export const GET_CART = "GET_CART";
export const GET_CART_OPEN = "GET_CART_OPEN";

export const updateCart = (data, products) => {
  console.log({ data, products });

  const existing = products.find(
    (p) =>
      p.cardSerial === data.cardSerial && p.typeSelected === data.typeSelected
  );

  const newQty = existing ? +data.qty + +existing.qty : 1;

  let list = [...products];

  if (!existing) {
    list = [...products, data];
  } else if (newQty <= 8) {
    list = products.map((p) => {
      return p.cardSerial === data.cardSerial &&
        p.typeSelected === data.typeSelected
        ? { ...data, qty: +p.qty + +data.qty }
        : p;
    });
  } else if (newQty > 8) {
    window.alert(`maximum 8 for this card, ${existing.qty} in cart now`);
  }

  return {
    type: GET_CART,
    payload: {
      cart: list,
    },
  };
};

export const removeCartItem = (data, products) => {
  const filterCart = products.filter((item) => {
    return !(
      item.cardSerial === data.cardSerial &&
      item.typeSelected === data.typeSelected
    );
  });

  // console.log("products", products);
  // console.log("data", data);
  // console.log("filterCart", filterCart);

  return {
    type: GET_CART,
    payload: {
      cart: filterCart,
    },
  };
};

export const clearCart = () => {
  return {
    type: GET_CART,
    payload: {
      cart: [],
    },
  };
};
export const updateIsCartOpen = (isOpen) => {
  return {
    type: GET_CART_OPEN,
    payload: {
      cartDrawerOpen: isOpen,
    },
  };
};
