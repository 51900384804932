import { NUMERIC_REGEX } from "../data/const";

export const convertCurrency = (number) => {
  const formatter = new Intl.NumberFormat("th-TH", {
    style: "currency",
    currency: "THB",
  });

  return formatter.format(+number);
};

export const readFileDataTo64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

export const convertDate = (date) => {
  let d = new Date(date);
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  return `${da}-${mo}-${ye}`;
};

export const convertBooleanFromString = (boolStr) => {
  return boolStr === "true" ? true : boolStr === "false" ? false : null;
};

export const onHandleOnlyNumber = (e) => {
  if (!NUMERIC_REGEX.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
};
