import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const styles = {
    list: {
      listStyle: "none",
    },
    container: {
      width: "100%",
      height: "280px",
      color: "",
      borderRadius: "40px 40px 0px 0px",
      backgroundColor: "white",
      margin: "auto",
      position: "absolute",
      bottom: "-280px",
    },
    textHeader: {
      fontSize: "20",
      fontWeight: "600",
    },
    imgLogo: {
      width: "200px",
      height: "175px",
    },
  };

  const customerServiceList = [
    { label: "Privacy Policy", path: "/policy" },
    { label: "Terms of Service", path: "/termofservice" },
    { label: "Contact Us", path: "/contactus" },
  ];

  const displayLogo = (
    <div className="h-100 d-flex justify-content-end ">
      <img src="/assets/img/logo4.png" alt="logo" style={styles.imgLogo} />
    </div>
  );

  const displayQuickLink = (
    <div>
      <div style={styles.textHeader}>Customer Service</div>
      {customerServiceList.map((item, index) => {
        return (
          <li style={styles.list} key={index}>
            <Link to={item.path}>{item.label}</Link>
          </li>
        );
      })}
    </div>
  );

  const displayAddress = (
    <div>
      <div style={styles.textHeader}>Visit Our Store</div>
      <p>5640 E. Taft Road, #3267 Syracuse, NY 13220 Email Us</p>
      <p>MONDAY-FRIDAY 09:00 - 18:00 STAURDAY 10:00 - 17:00</p>
    </div>
  );
  const displayConnection = (
    <div>
      <div style={styles.textHeader}>Connect With Us</div>
      <div className="mt-2">
        <img src="/assets/img/facebook.png" alt="Facebook" />
        <span className="ms-3">Destiny Draw</span>
      </div>
      <div className="mt-2">
        <img src="/assets/img/line.png" alt="Line" />
        <span className="ms-3">Destiny Draw</span>
      </div>
      <div className="mt-2">
        <img src="/assets/img/instagram.png" alt="instgram" />
        <span className="ms-3">Destiny Draw</span>
      </div>
    </div>
  );

  return (
    <div style={styles.container}>
      <div className="row pt-5 h-100">
        <div className="col-3 ">{displayLogo}</div>
        <div className="col-3 ">{displayAddress}</div>
        <div className="col-3 ">{displayQuickLink}</div>
        <div className="col-3 ">{displayConnection}</div>
      </div>
    </div>
  );
};

export default Footer;
