import React from "react";

const BadgeEl = ({ title, fullName, trackingNo, icon, address, phone }) => {
  const styles = {
    iconContainer: {
      width: "40px",
      height: "40px",
      backgroundColor: "rgba(1, 22, 39, 1)",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "10px",
    },
  };
  // <HiOutlineHome size="1.5rem" color="white" />
  return (
    <div className="card">
      <div className="card-body lh-3 d-flex">
        <div>
          <div style={styles.iconContainer}>{icon}</div>
        </div>
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="H6">{title}</div>
            <div>{trackingNo && `Tracking no. ${trackingNo}`}</div>
          </div>
          <div>{fullName}</div>
          <div>{phone}</div>
          <div>{address}</div>
        </div>
      </div>
    </div>
  );
};

export default BadgeEl;
