import { GET_CART, GET_CART_OPEN } from "../action/cartAction";

const initState = {
  cart: [],
  cartDrawerOpen: false,
};

const cartReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CART:
      return {
        ...state,
        cart: action.payload.cart,
      };
    case GET_CART_OPEN:
      return {
        ...state,
        cartDrawerOpen: action.payload.cartDrawerOpen,
      };

    default:
      break;
  }

  return state;
};

export default cartReducer;
