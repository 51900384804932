import React, { useState, useEffect } from "react";
import { convertCurrency } from "../Services/Func";
import Select from "react-select";
import { BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { removeCartItem } from "../redux/action/cartAction";

const OrderDetailItem = ({ item, isCheckout }) => {
  const [qty, setQty] = useState({ label: "0", value: "0" });

  const styles = {
    image: {
      width: "100%",

      objectFit: "contain",
    },
    imageContainer: {
      width: "100px",
      height: "128px",
      display: "flex",
    },
    title: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      lineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
  };

  let optionQty = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
  ];

  const onSetQty = (ev) => {
    setQty(ev);
  };

  // const onRemoveItem = (data) => {
  //   dispatch(removeCartItem(data, cart));
  // };

  useEffect(() => {
    if (item) {
      const res = optionQty.find((el) => el.value == item.qty);

      setQty(res);
    }
  }, [item]);

  return (
    <div className="row my-4">
      <div className="col-auto">
        <div style={styles.imageContainer}>
          <img
            src={item?.card?.img}
            alt={item?.card?.name}
            style={styles.image}
          />
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fw-bold" style={styles.title}>
            {item?.card?.name}
          </div>
          {/* <div className="btn" onClick={() => onRemoveItem(item)}>
              <BiTrash />
            </div> */}
        </div>
        <div>{item.condition}</div>
        <div className="row">
          <div className="col">
            <div>{item?.card?.optionalDetail?.rarity}</div>
          </div>
          <div className="col text-end">
            <div>{convertCurrency(item.price ?? 0)}</div>
            <div>
              {isCheckout ? (
                <span>X {item.amount}</span>
              ) : (
                <Select
                  options={optionQty}
                  onChange={onSetQty}
                  value={qty}
                  defaultValue={qty}
                />
              )}
              {item.canConfirm === false ? (
                <div className="text-danger">Out of stock</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailItem;
