import React from "react";

const Banner = ({ title }) => {
  const styles = {
    banner: {
      width: "100%",
      height: "100%",
      // maxHeight: "300px",
      objectFit: "contain",
      // background: " url(/assets/img/banner.jpg)",
      // background:
      //   "linear-gradient(0deg, rgba(1, 22, 39, 0.5) 0%, rgba(111, 156, 235, 0.45) 100%), url(/assets/img/banner.jpg)",
    },
    text: {
      fontSize: "60px",
      position: "absolute",
      top: "50%",
      right: "50%",
      transform: "translate(50%, -50%)",
      color: "white",

      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      lineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
  };

  return (
    <div className="position-relative" style={styles.banner}>
      <img src="/assets/img/banner.jpg" style={styles.banner} />
      {/* <span style={styles.text}>{title}</span> */}
    </div>
  );
};

export default Banner;
