import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAddress,
  postUserCreateAddress,
  postUserUpdateAddress,
} from "../Services/Crud";
import InputField from "../Components/InputField";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { updateIsLoading } from "../redux/action/dataAction";

const MyAddress = () => {
  const dispatch = useDispatch();
  // const profile = useSelector((state) => state.profileReducer.profile);
  const profile = JSON.parse(localStorage.getItem("dtnProfile"));

  const [userDetail, setUserDetail] = useState();

  const hookForm = useForm();
  const { register, watch, reset } = hookForm;
  const watchValue = watch();

  const styles = {
    textHeader: {
      fontSize: "20px",
      fontWeight: "600",
      color: "#414749",
    },
  };

  const onHandleSave = async () => {
    try {
      const data = {
        user: profile.userID,
        // isBillingAddress: watchValue?.isBillingAddress,
        // isShippingAddress: watchValue?.isShippingAddress,
        phoneNo: watchValue?.phoneNo,
        subDistinct: watchValue?.subDistrict,
        distinct: watchValue?.district,
        province: watchValue?.province,
        postcode: watchValue?.postcode,
        etc: watchValue?.address,
      };

      let res;

      if (!userDetail) {
        res = await postUserCreateAddress(data);
      } else {
        res = await postUserUpdateAddress(data);
      }

      console.log({ res });
    } catch (err) {
      console.log({ err });
    }
  };

  const getUserDetail = (userId) => {
    dispatch(updateIsLoading(true));
    getUserAddress(userId)
      .then((res) => {
        const user = res.data[0];

        const data = {
          user: user?.user,
          isBillingAddress: user?.isBillingAddress,
          isShippingAddress: user?.isShippingAddress,
          phoneNo: user?.phoneNo,
          subDistrict: user?.subDistinct,
          district: user?.distinct,
          province: user?.province,
          postcode: user?.postcode,
          address: user?.etc,
        };

        setUserDetail(user);
        reset(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateIsLoading(false));
      });
  };
  console.log({ profile });
  useEffect(() => {
    if (profile && profile.userID) {
      getUserDetail(profile.userID);
    }
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <div style={styles.textHeader}>My Profile</div>

        <div className="row my-3 g-3">
          <div className="col-6">
            <InputField
              hookForm={hookForm}
              label="First Name"
              keyName="firstName"
            />
          </div>
          <div className="col-6">
            <InputField
              hookForm={hookForm}
              label="Last Name"
              keyName="lastName"
            />
          </div>
          <div className="col-6">
            <InputField
              type="email"
              hookForm={hookForm}
              label="Email"
              keyName="email"
            />
          </div>
          <div className="col-6">
            <InputField
              hookForm={hookForm}
              label="Phone Number"
              keyName="phoneNo"
            />
          </div>
          <div className="col-6">
            <InputField
              hookForm={hookForm}
              label="Sub district"
              keyName="subDistrict"
            />
          </div>
          <div className="col-6">
            <InputField
              hookForm={hookForm}
              label="District"
              keyName="district"
            />
          </div>
          <div className="col-6">
            <InputField
              hookForm={hookForm}
              label="Province"
              keyName="province"
            />
          </div>
          <div className="col-6">
            <InputField
              hookForm={hookForm}
              label="Postcode"
              keyName="postcode"
            />
          </div>
          <div className="col-12">
            <InputField
              hookForm={hookForm}
              label="Stree Name, Building, House No."
              keyName="address"
              multiline
              minRows={3}
            />
          </div>
        </div>

        {/* <div>
          <div>Use this address as default for</div>

          <div className="d-flex">
            <div className="form-check me-3">
              <input
                className="form-check-input"
                type="checkbox"
                {...register("isShippingAddress")}
              />
              <label className="form-check-label">Shipping Address</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                {...register("isBillingAddress")}
              />
              <label className="form-check-label">Billing Address</label>
            </div>
          </div>
        </div> */}

        <div className="text-end">
          <Button variant="contained" onClick={onHandleSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MyAddress;
