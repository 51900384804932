import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { mtgApi } from "../api/mtgAPI";
import CardDetailContainer from "../Components/HomeComponents/CardDetailContainer";
import { KeyboardDoubleArrowDown } from "@mui/icons-material";
import {
  getAdvanceSearchEdition,
  postGetAllEditionByGame,
} from "../Services/Crud";
import _ from "lodash";
import { useForm } from "react-hook-form";
import {
  updateCollection,
  updateIsLoading,
  updateTextSearch,
} from "../redux/action/dataAction";
import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { convertBooleanFromString } from "../Services/Func";

const AdvanceSearch = ({ data }) => {
  let { gId } = useParams();
  const dispatch = useDispatch();
  const searchBtn = document.querySelector("#search-btn");

  const defaultValues = {
    filterChecked: {
      Variations: false,
      Colors: [],
      Rarity: [],
    },
  };
  const { register, watch, getValues, reset } = useForm({ defaultValues });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const [relatedProduct, setRelatedProduct] = useState([]);
  const [results, setResults] = useState([]);
  const [notFoundText, setNotFoundText] = useState("");
  const [isMore, setIsMore] = useState(true);

  const [filterResult, setFilterResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [optionGameEditions, setOptionGameEditions] = useState([]);
  const [editionSelected, setEditionSelected] = useState("");
  const [isInStock, setIsInStock] = useState(true);
  const productsSearch = useSelector(
    (state) => state.dataReducer.productsSearch
  );
  const collectionSelected = useSelector(
    (state) => state.dataReducer.collectionSelected
  );
  const textSearch = useSelector((state) => state.dataReducer.textSearch);
  const watchFilterChecked = watch("filterChecked");

  const styles = {
    input: {
      backgroundColor: "white",
    },
  };

  const optionsOnStock = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const getAllEdition = () => {
    dispatch(updateIsLoading(true));

    postGetAllEditionByGame(gId)
      .then((res) => {
        const opt = res.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        });

        setOptionGameEditions(opt);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateIsLoading(false));
      });
  };

  // const onHandleGetAll = async (gameMasterId) => {
  //   setIsInStock(false);

  //   dispatch(updateIsLoading(true));
  //   setFilterResult([]);
  //   getProductPage(gameMasterId)
  //     .then((res) => {
  //       console.log("productpage ", res.data.card);
  //       setResults(res.data.card);
  //       setFilterResult(res.data.card);
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => {
  //       dispatch(updateIsLoading(false));
  //     });
  // };

  const onHandleSearch = (limit = 30, page = 1) => {
    dispatch(updateIsLoading(true));
    const data = {
      name: textSearch || searchTerm,
      gameEdition:
        collectionSelected ||
        (editionSelected === "all" ? "" : editionSelected),
      inStock: isInStock,
    };
    getAdvanceSearchEdition(gId, data, limit, page)
      .then((res) => {
        // setResults(res.data.data);
        // setFilterResult(res.data.data);
        console.log("res", res);

        setResults((prevResults) => [...prevResults, ...res.data.data]);
        setFilterResult((prevResults) => [...prevResults, ...res.data.data]);
        if (!res.data.data.length) {
          setNotFoundText("ไม่พบข้อมูล");
        }

        if (res.data.data.length < limit) {
          setIsMore(false);
        } else {
          setIsMore(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        dispatch(updateIsLoading(false));
      });
  };

  const onHandleMore = () => {
    const newPage = page + 1;

    onHandleSearch(rowsPerPage, newPage);
    setPage(newPage);
  };

  const onEditionSelected = (ev) => {
    setEditionSelected(ev.target.value);
  };

  useEffect(() => {
    if (collectionSelected && optionGameEditions.length) {
      setEditionSelected(collectionSelected);

      onHandleSearch(rowsPerPage, 1);
      setTimeout(() => {
        dispatch(updateCollection(""));
      }, 2000);
    }
  }, [collectionSelected, optionGameEditions]);

  useEffect(() => {
    if (textSearch) {
      setSearchTerm(textSearch);

      onHandleSearch(rowsPerPage, 1);
      setTimeout(() => {
        dispatch(updateTextSearch(""));
      }, 2000);
    }
  }, [textSearch]);

  useEffect(() => {
    if (gId) {
      getAllEdition();
    }

    // if (productsSearch.length) {
    //   setResults(productsSearch);
    //   setFilterResult(productsSearch);
    // } else if (!productsSearch.length && gId) {
    //   onHandleSearch();
    // }
  }, [gId, productsSearch]);

  useEffect(() => {
    try {
      const optionnalList = results
        .map((item) => {
          return item.optionalDetail;
        })
        .filter((data) => data);

      const getVariation = optionnalList.map((item) =>
        !item.variation
          ? { label: "Singles", value: false }
          : { label: "Foils", value: true }
      );

      const uniqVariations = _.uniqBy(getVariation, "label");

      const getColors = optionnalList.map((item) => {
        let getColor = "";

        if (item.colors && item.colors[0] === "W") {
          getColor = "White";
        } else if (item.colors && item.colors[0] === "G") {
          getColor = "Green";
        } else if (item.colors && item.colors[0] === "B") {
          getColor = "Black";
        } else if (item.colors && item.colors[0] === "U") {
          getColor = "Blue";
        } else if (item.colors && item.colors[0] === "R") {
          getColor = "Red";
        } else {
          getColor = "None";
        }

        return item.colors && item.colors.length
          ? { label: getColor, value: item.colors[0] }
          : { label: "None", value: "None" };
      });

      const uniqColors = _.uniqBy(getColors, "value");

      console.log({ uniqColors });

      const getRarity = optionnalList.map((item) => {
        return { label: item.rarity, value: item.rarity };
      });

      const uniqRarity = _.uniqBy(getRarity, "value");

      const filterList = [
        {
          name: "Variations",
          data: uniqVariations,
        },
        {
          name: "Colors",
          data: uniqColors,
        },
        {
          name: "Rarity",
          data: uniqRarity,
        },
      ];

      setFilterOptions(filterList);
      // setResults(results);
      // setFilterResult(results);
    } catch (error) {
      console.log(error);
    }
  }, [results]);

  console.log("results", results);

  const onApplyFilter = () => {
    const {
      filterChecked: { Variations, Colors, Rarity },
    } = getValues();

    if (!watchFilterChecked) {
      setFilterResult(results);
    } else {
      const filterVariation = results.filter((item) => {
        return (
          item?.optionalDetail?.variation ===
          convertBooleanFromString(Variations)
        );
      });

      const filterColor = results.filter((item) => {
        return (
          item?.optionalDetail?.colors?.length &&
          Colors.indexOf(item?.optionalDetail?.colors[0]) !== -1
        );
      });

      const filterRarity = results.filter((item) => {
        return (
          item?.optionalDetail?.rarity?.length &&
          Rarity.indexOf(item?.optionalDetail?.rarity) !== -1
        );
      });

      const total = [...filterVariation, ...filterColor, ...filterRarity];

      const uniqTotal = _.uniqBy(total, "_id");

      setFilterResult(uniqTotal.length ? uniqTotal : results);
    }
  };

  const onClearFilter = () => {
    reset();

    setFilterResult(results);
  };

  const displaySearchInput = (
    <div className="px-5 my-3">
      <h6>Search</h6>
      <div className="row">
        <div className="col">
          <TextField
            label="Search Card Name"
            variant="outlined"
            onChange={(ev) => setSearchTerm(ev.target.value)}
            value={searchTerm}
            fullWidth
            sx={styles.input}
          />
        </div>
        <div className="col">
          <TextField
            label="Variations"
            variant="outlined"
            fullWidth
            disabled
            sx={styles.input}
          />
        </div>
        <div className="col">
          <FormControl fullWidth>
            <InputLabel id="select-edition-label">Edition</InputLabel>
            <Select
              labelId="select-edition-label"
              id="select-edition"
              value={editionSelected}
              label="Edition"
              onChange={onEditionSelected}
              sx={styles.input}
            >
              <MenuItem value="all">All</MenuItem>
              {!!optionGameEditions.length &&
                optionGameEditions.map((el, elIndex) => {
                  return (
                    <MenuItem key={elIndex} value={el.value}>
                      {el.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <div className="col">
          <FormControlLabel
            control={
              <Checkbox
                checked={isInStock}
                onChange={(ev) => setIsInStock(ev.target.checked)}
              />
            }
            label="  In Stock"
          />
        </div>
        <div className="col-1 text-center">
          <button
            className="btn btn-primary"
            onClick={() => {
              setResults([]);
              setFilterResult([]);
              setPage(1);
              setIsMore(true);
              onHandleSearch(rowsPerPage, 1);
            }}
            id="search-btn"
          >
            Search
          </button>
        </div>
        <div className="col-1 text-center">
          <button
            disabled={!filterResult.length}
            className="btn btn-outline-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvas--filter"
            aria-controls="offcanvas--filter"
          >
            <img src="/assets/img/filter.png" alt="filter" />
          </button>
        </div>
      </div>
    </div>
  );

  const displayFilter = (
    <div>
      {filterOptions.length
        ? filterOptions.map((item, fIndex) => {
            return (
              <div className="my-3" key={fIndex}>
                <div className="fw-bold">{item.name}</div>
                {item.data.length &&
                  item.data.map((data, iIndex) => {
                    return (
                      <div className="form-check" key={iIndex}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={data.value}
                          {...register(`filterChecked.${item.name}`, {
                            // onChange: onHandleChecked,
                          })}
                        />
                        <label className="form-check-label">{data.label}</label>
                      </div>
                    );
                  })}
              </div>
            );
          })
        : ""}

      <div className="my-2">
        <button className="btn btn-primary w-100" onClick={onApplyFilter}>
          Apply Filter
        </button>
      </div>
      <div className="my-2">
        <button
          className="btn btn-outline-primary w-100"
          onClick={onClearFilter}
        >
          Clear Filter
        </button>
      </div>
    </div>
  );

  const sliderFilter = (
    <div
      className="offcanvas offcanvas-end p-3"
      tabIndex={-1}
      id="offcanvas--filter"
      aria-labelledby="offcanvas--filterLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasLabel">
          Filters
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <hr />
      <div className="offcanvas-body">{displayFilter}</div>
    </div>
  );

  return (
    <div className="container-fluid">
      {displaySearchInput}
      <div className="my-2">
        <CardDetailContainer products={filterResult} />
        {isMore && !!filterResult.length && (
          <Button
            sx={{ marginBottom: "20px", borderRadius: "25px" }}
            onClick={onHandleMore}
            variant="outlined"
            fullWidth
            startIcon={<KeyboardDoubleArrowDown />}
          >
            More
          </Button>
        )}
      </div>
      {sliderFilter}
    </div>
  );
};

export default AdvanceSearch;
