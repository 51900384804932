import React, { useState, useEffect } from "react";
import { storeApi } from "../fakeApi/storeApi";
import CartItem from "../Elements/CartItem";
import { convertCurrency } from "../Services/Func";
import { useDispatch, useSelector } from "react-redux";

const ProductsOrdered = () => {
  const cart = useSelector((state) => state.cartReducer.cart);

  const [totalPrice, setTotalPrice] = useState(0);

  const styles = {
    cardBody: {
      height: "60vh",
      overflowY: "scroll",
      overflowX: "hidden",
      padding: "10px",
    },

    cardFooter: {
      height: "208px",
      backgroundColor: "#011627",
      color: "white",
      padding: "15px",
      lineHeight: "175%",
    },
  };

  console.log({ totalPrice });

  useEffect(() => {
    const datas = cart.map((item) => {
      return {
        price: +item.price[item.typeSelected] ?? 0,
        qty: item.qty,
      };
    });

    const total = datas.reduce((total, num) => {
      return total + num.price * num.qty;
    }, 0);

    setTotalPrice(+total);
  }, [cart]);

  return (
    <div>
      <div className="card w-100">
        <div className="card-body p-3 ">
          <h5 className="card-title">Products Ordered</h5>
          <hr />
          <div style={styles.cardBody} className="custom-scrollbar">
            {cart.length
              ? cart.map((item, index) => {
                  return <CartItem key={index} item={item} isCheckout />;
                })
              : ""}
          </div>
        </div>
        <div className="card-footer" style={styles.cardFooter}>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "16px" }}
          >
            <div>Merchandise Subtotal</div>
            <div>{convertCurrency(totalPrice)}</div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "16px" }}
          >
            <div>Shipping Total</div>
            <div>{convertCurrency(50)}</div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "20px" }}
          >
            <div>Total Payment</div>
            <div>{convertCurrency(totalPrice + 50)}</div>
          </div>
          {/* {callbackFn ? (
            <div className="mt-4">
              <button className="btn btn-primary w-100" onClick={callbackFn}>
                Place Order
              </button>
            </div>
          ) : (
            ""
          )} */}
          <div className="mt-4">
            <button className="btn btn-primary w-100" type="submit">
              Place Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsOrdered;
