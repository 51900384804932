import React, { useState, useEffect } from "react";
import ProductsOrdered from "../Components/ProductsOrdered";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mtgApi } from "../api/mtgAPI";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { clearCart } from "../redux/action/cartAction";
import { Grid, TextField } from "@mui/material";
import InputField from "../Components/InputField";
import { updateIsLoading } from "../redux/action/dataAction";
import { getUserAddress } from "../Services/Crud";
import { onHandleOnlyNumber } from "../Services/Func";

const Checkout = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const hookForm = useForm();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = hookForm;

  const history = useHistory();
  const cart = useSelector((state) => state.cartReducer.cart);

  // const profile = useSelector((state) => state.profileReducer.profile);
  const profile = JSON.parse(localStorage.getItem("dtnProfile"));

  const [deleverMethod, setDeleverMethod] = useState(1);
  const [placeOrdered, setPlaceOrdered] = useState(null);
  const [cartRes, setCartRes] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const styles = {
    textPart: {
      fontSize: "20px",
      fontWeight: "600, Semi Bold",
      marginBlock: "20px",
    },
    headerText: {
      fontSize: "34px",
      fontWeight: "500",
    },
    confirmedContainer: {
      marginBlock: "50px",
      minHeight: "600px",
      height: "60vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    confirmedImg: {
      width: "400px",
      height: "300px",
    },
  };

  const getUserDetail = (userId) => {
    dispatch(updateIsLoading(true));
    getUserAddress(userId)
      .then((res) => {
        const user = res.data[0];

        const data = {
          // user: user?.user,
          // isBillingAddress: user?.isBillingAddress,
          // isShippingAddress: user?.isShippingAddress,
          phoneNo: user?.phoneNo,
          subDistrict: user?.subDistinct,
          district: user?.distinct,
          province: user?.province,
          postcode: user?.postcode,
          address: user?.etc,

          firstName: profile?.displayName?.split(" ")[0],
          lastName: profile?.displayName?.split(" ")[1],
          email: profile?.email,
        };

        // setUserDetail(user);
        reset(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateIsLoading(false));
      });
  };

  useEffect(() => {
    if (profile && profile.userID) {
      getUserDetail(profile.userID);
    }
  }, []);

  useEffect(() => {
    const datas = cart.map((item) => {
      return {
        price: +item.price.nm ?? 0,
        qty: item.qty,
      };
    });

    const total = datas.reduce((total, num) => {
      return total + num.price * num.qty;
    }, 0);

    setTotalPrice(+total);
  }, [cart]);

  const onPlaceOrder = () => {
    console.log({ errors });

    // if (!profile) {
    //   window.alert("Please Login");
    // } else {
    dispatch(updateIsLoading(true));
    const tempCartRes = [...cart];

    const groupCards = _.groupBy(tempCartRes, "cardSerial");

    const reGroupCards = Object.keys(groupCards).map((cs) => {
      return groupCards[cs];
    });

    const orderDetail = reGroupCards.map((el) => {
      return {
        cardSerial: el[0].cardSerial,
        data: el.map((c) => {
          return {
            condition: c.typeSelected?.toUpperCase(),
            priceType: "normal",
            amount: c.qty,
            price: c.price[c.typeSelected],
            summary: c.qty * c.price[c.typeSelected],
          };
        }),
      };
    });

    const { phoneNo, subDistrict, district, province, postcode, address } =
      watch();

    const data = {
      user: profile?.userID,
      summary: +totalPrice,
      shipping: 50,
      total: +totalPrice + 50,
      orderDetail,
      address: {
        phoneNo: phoneNo,
        subDistinct: subDistrict,
        distinct: district,
        province: province,
        postcode: postcode,
        etc: address,
      },
    };

    console.log("data", data);
    console.log("totalPrice", totalPrice);

    mtgApi
      .post(`/order/checkOut`, data)
      .then((res) => {
        setPlaceOrdered(res.data.data);
        dispatch(clearCart());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateIsLoading(false));
      });
    // }
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    console.log("ValidateEmail was called with", email);

    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
      console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    }

    return isValid;
  };

  const displayDelivery = (
    <div>
      <div style={styles.textPart}>Choose Delivery Method</div>
      <div className="row">
        <div className="col-6 ">
          <div className="w-100 border rounded p-2">
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                name="deliverymethod"
                value={1}
                defaultChecked
                onChange={(ev) => setDeleverMethod(ev.target.value)}
              />
              <label className="form-check-label">Delivery</label>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="w-100 border rounded p-2">
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                name="deliverymethod"
                disabled
                value={2}
                onChange={(ev) => setDeleverMethod(ev.target.value)}
              />
              <label className="form-check-label">Pickup</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const displayAddress = (
    <div className="my-2">
      <h4 style={styles.textPart}>Address</h4>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputField
            hookForm={hookForm}
            keyName="firstName"
            label="First Name / ชื่อ"
            required={true}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            hookForm={hookForm}
            keyName="lastName"
            label="Last Name / นามสกุล"
            required={true}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            hookForm={hookForm}
            keyName="email"
            label="Email / อีเมล"
            type="email"
            hookProps={{
              onChange: handleEmailValidation,
            }}
            required={true}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            required={true}
            onKeyDown={onHandleOnlyNumber}
            // type="number"
            hookForm={hookForm}
            keyName="phoneNo"
            label="Phone Number / เบอร์โทรศัพท์"
            hookProps={{
              maxLength: 10,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            required={true}
            hookForm={hookForm}
            keyName="subDistrict"
            label="Sub district / ตำบล"
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            required={true}
            hookForm={hookForm}
            keyName="district"
            label="District / อำเภอ"
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            required={true}
            hookForm={hookForm}
            keyName="province"
            label="Province / จังหวัด"
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            required={true}
            onKeyDown={onHandleOnlyNumber}
            // type="number"
            hookForm={hookForm}
            keyName="postcode"
            label="Postcode / รหัสไปรษณีย์"
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            required={true}
            hookForm={hookForm}
            keyName="address"
            label="Stree Name, Building, House No. / ที่อยู่"
            multiline
            minRows={3}
          />
        </Grid>
      </Grid>
    </div>
  );

  const displayPickup = (
    <div className="my-2">
      <div style={styles.textPart}>Pickup location</div>
      <div className="row  g-3">
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder="First Name / ชื่อ"
          />

          {/* <TextField variant="outlined" label="First Name / ชื่อ" /> */}
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder="Last Name / นามสกุล"
          />
        </div>
        <div className="col-6">
          <input
            type="email"
            className="form-control"
            placeholder="Email / อีเมล"
          />
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder="Phone Number / เบอร์โทรศัพท์"
          />
        </div>

        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder="Location / ระบุสถานที่"
          />
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder="Time / เวลา"
          />
        </div>
      </div>
    </div>
  );

  const displayForm = (
    <div className="card">
      <div className="card-body">
        {/* {displayDelivery} */}
        {displayAddress}
      </div>
    </div>
  );

  const displayConfirmedOrder = (
    <div style={styles.confirmedContainer}>
      <div className="text-center">
        <img
          src="/assets/img/confirmed-order.png"
          alt="confirmed"
          style={styles.confirmedImg}
        />
        <div style={styles.headerText}>Order Successfully Placed</div>
        <div style={styles.textPart}>
          Your Order Number{" "}
          <span style={{ color: "#F4694F" }}>#{placeOrdered?.order?._id}</span>
        </div>
        <div className="text-center my-3">
          <button
            className="btn btn-outline-primary mx-3"
            onClick={() => {
              history?.push("/myorders");
            }}
          >
            Review Order
          </button>
          <button
            className="btn btn-primary mx-3"
            onClick={() => {
              history.push(`/order/${placeOrdered?.order?._id}`);
            }}
          >
            Transfer
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container my-3">
      {!placeOrdered ? (
        <>
          <div style={styles.headerText}>Checkout</div>
          <form onSubmit={handleSubmit(onPlaceOrder)}>
            <div className="row g-2">
              <div className="col-lg-7">{displayForm}</div>
              <div className="col-lg-5">
                <ProductsOrdered />
              </div>
            </div>
          </form>
        </>
      ) : (
        displayConfirmedOrder
      )}
    </div>
  );
};

export default Checkout;
