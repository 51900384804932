import { mtgApi } from "../api/mtgAPI";

export const getAllGameByDate = async () => {
  const res = await mtgApi.get("/game/getAllByDate/1/20");

  return res;
};

export const postGetAllEditionByGame = async (gameMasterId) => {
  const data = {
    gameMaster: gameMasterId,
  };

  const res = await mtgApi.post("/edition/getAllEditionByGame", data);

  return res.data;
};

export const addGameCollection = async ({ name, description, imageURL }) => {
  const data = {
    name,
    description,
    imageURL,
  };
  const res = await mtgApi.post("/game/addNewGame", data);

  return res;
};

export const advanceSearchGame = async (name) => {
  const data = {
    name,
  };

  const res = await mtgApi.post("/game/advSearchGame", name ? data : {});

  return res;
};

export const getAdvanceSearchEdition = async (
  gId,
  data,
  limit = 50,
  page = 1
) => {
  const res = await mtgApi.post(
    `/card/advSearchEdition/${gId}?limit=${limit}&page=${page}`,
    data
  );

  return res;
};

export const deleteGameById = async (id) => {
  const data = {
    id,
  };

  const res = await mtgApi.post("/game/delGameById", data);

  return res;
};

export const getAllEditionByName = async (name) => {
  const res = await mtgApi.get(`/edition/getAllEdition/${name}/20/,`);

  return res;
};

export const getAllCardByName = async (name = ",") => {
  const res = await mtgApi.get(`/card/getAllByName/${name}/1/10`);

  return res;
};

export const addGameEdition = async ({
  gameMaster,
  name,
  description,
  imageURL,
}) => {
  const data = {
    gameMaster,
    name,
    description,
    imageURL,
  };

  const res = await mtgApi.post("/edition/addNewEdition", data);

  return res;
};

export const getOptionCard = async (gameId) => {
  const res = await mtgApi.post(`/card/${gameId}`);

  return res;
};
export const getProductPage = async (gameMasterId) => {
  // const data = {
  //   gameMaster: gameMasterId,
  // };

  const res = await mtgApi.get(`card/productPage/${gameMasterId}`);

  return res.data;
};

// export const getCardDetail = async (cardId) => {
//   const res = await mtgApi.get(`/card/getCardDetail/${cardId}`);

//   return res.data;
// };

export const getCardDetail = async (cardSerial) => {
  const data = {
    cardSerial,
    priceType: "normal",
  };

  const res = await mtgApi.post(`/card/getCardDetail`, data);

  return res.data;
};
export const getUserOrder = async (userId) => {
  const res = await mtgApi.get(`/order/getAllOrderByUser/${userId}`);

  return res.data;
};

export const getUserOrderDetail = async (orderId) => {
  const res = await mtgApi.get(`/order/getOrderDetailByUser/${orderId}`);

  return res.data;
};
export const getAListOrderDetail = async (orderNo) => {
  const res = await mtgApi.get(`/order/AlistOrderDetail/${orderNo}`);

  return res.data;
};

export const getUserAddress = async (userId) => {
  const res = await mtgApi.get(`/user/getAddress/${userId}`);

  return res.data;
};

export const postUserCreateAddress = async (data) => {
  const sampleData = {
    user: "62c13542282d443c81ce700c",
    isBillingAddress: true,
    isShippingAddress: true,
    phoneNo: "0857161204",
    subDistinct: "chongnonsi",
    distinct: "Yanawa",
    province: "Bangkok",
    postcode: "10120",
    etc: "549 , Mahatoon Shopping Arcard Bld, Room 714",
  };

  const res = await mtgApi.post(`/user/createAddress`, data);

  return res.data;
};

export const postUserUpdateAddress = async (data) => {
  const sampleData = {
    user: "62c13542282d443c81ce700c",
    isBillingAddress: true,
    isShippingAddress: true,
    phoneNo: "0857161204",
    subDistinct: "chongnonsi",
    distinct: "Yanawa",
    province: "Bangkok",
    postcode: "10120",
    etc: "549 , Mahatoon Shopping Arcard Bld, Room 714",
  };

  const res = await mtgApi.post(`/user/updateAddress`, data);

  return res.data;
};

export const postUploadSlip = async (orderId, img64) => {
  const data = {
    id: orderId,
    paymentSlip: img64,
  };

  const res = await mtgApi.post(`/order/confirmOrdersPayment`, data);

  return res.data;
};
