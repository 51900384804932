import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { convertDate } from "../Services/Func";

const OrderItemEl = ({ item }) => {
  const { url } = useRouteMatch();

  console.log({ item });
  const generateStatus = (item) => {
    if (item.isDelivered) {
      return <div className="text-success">Shipped</div>;
    } else if (item.isDeleted) {
      return <div className="text-danger">Deleted</div>;
    } else if (item.isCanceled) {
      return <div className="text-danger">Canceled</div>;
    } else if (item.isConfirm) {
      return <div className="text-warning">Amount Confirmed</div>;
    } else if (item.isPayment) {
      return <div className="text-warning">Pending Order</div>;
    } else if (item.isPickup) {
      return <div className="text-warning">Pickup</div>;
    } else {
      return <div className="text-warning">Pending Payment</div>;
    }
  };

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>Order Number #{item?.orderNo}</div>
        <div className="H6">{generateStatus(item)}</div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="text--secondary">
          Order Date: {convertDate(item.createdAt)}
        </div>
        <div className="d-flex align-items-end">
          {/* {!item?.order?.isDelivered && (
            <span className="badge rounded-pill bg-danger mx-2">
              Expiry date 12-12-2022
            </span>
          )}
          {!item?.order?.isDelivered && (
            <button className="btn btn-sm btn-primary mx-2">Transfer</button>
          )} */}
          <Link to={`/order/${item?._id}`}>
            <button className="btn btn-sm btn-outline-primary ms-2">
              Order Detail
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderItemEl;
