import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CardDetailEl from "../Elements/CardDetailEl";
import { storeApi } from "../fakeApi/storeApi";
import { FiSearch } from "react-icons/fi";
import CardDetailContainer from "../Components/HomeComponents/CardDetailContainer";
import { mtgApi } from "../api/mtgAPI";
import { getProductPage } from "../Services/Crud";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../redux/action/dataAction";

const Seal = () => {
  let { gId } = useParams();
  const dispatch = useDispatch();

  const [relatedProduct, setRelatedProduct] = useState([]);
  const [serchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const onHandleGetAll = async (gameMasterId) => {
    dispatch(updateIsLoading(true));
    getProductPage(gameMasterId)
      .then((res) => {
        setRelatedProduct(res.data.seal);
        setSearchResults(res.data.seal);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(updateIsLoading(false));
      });
  };

  useEffect(() => {
    if (gId) {
      // onHandleGetAll("62893b464048140c7019367b");
      onHandleGetAll(gId);
    }
  }, [gId]);

  useEffect(() => {
    try {
      const res = relatedProduct.filter((item) => {
        return item.name.toLowerCase().includes(serchTerm.toLowerCase());
      });

      setSearchResults(res);
    } catch (err) {
      console.log(err);
    }
  }, [serchTerm]);

  return (
    <div className="container-fluid">
      <div className="row my-3 mx-auto">
        <div className="col">
          <input
            type="text"
            className="form-control m-auto"
            placeholder="Search.."
            onChange={(ev) => setSearchTerm(ev.target.value)}
            value={serchTerm}
          />
        </div>
        <div className="col-2 text-center">
          <button className="btn btn-primary">
            <FiSearch /> Search
          </button>
        </div>
      </div>
      <div className="my-3">
        <CardDetailContainer products={searchResults} />

        {/* <div className="row g-2">
          {searchResults.length
            ? searchResults.map((item) => {
                return (
                  <div className="col-3">
                    <CardDetailEl item={item} />
                  </div>
                );
              })
            : ""}
        </div> */}
      </div>
    </div>
  );
};

export default Seal;
