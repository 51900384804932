import React from "react";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import { FcGoogle } from "react-icons/fc";
import { baseURL } from "../api/mtgAPI";

const ModalLogin = ({ modalIsOpen, setIsOpen }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",

      width: "400px",
      height: "300px",

      zIndex: "999",
    },
    overlay: {
      zIndex: "999",
    },
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onHandleLogin = () => {
    window.location = `${baseURL}/auth/google`;
    // window.location = "http://localhost:8080/auth/google";
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div>
        <div className=" p-2 d-flex justify-content-between align-items-center ">
          <div></div>
          <GrClose onClick={closeModal} role="button" />
        </div>
        <div className="text-center">
          <img src="/assets/img/logo4.png" width={100} alt="logo" />
          <h5>Destiny Draw</h5>
          <p>Welcome back.</p>
          <button
            className="btn btn-white w-100 rounded-3 border border-secondary"
            onClick={onHandleLogin}
          >
            <FcGoogle className="me-2 mb-1" size="1.2rem" /> Sign in with Google
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalLogin;
