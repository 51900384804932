export const GET_IS_MOBILE_MODE = "GET_IS_MOBILE_MODE";
export const GET_GAME_SELECTED = "GET_GAME_SELECTED";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_USD_EXCHANGE_RATE = "GET_USD_EXCHANGE_RATE";
export const GET_IS_LOADING = "GET_IS_LOADING";
export const GET_PRODUCTS_SEARCH = "GET_PRODUCTS_SEARCH";
export const GET_COLLECTION_SELECTED = "GET_COLLECTION_SELECTED";
export const GET_TEXT_SEARCH = "GET_TEXT_SEARCH";

export const updateIsMobileMode = (data) => {
  return {
    type: GET_IS_MOBILE_MODE,
    payload: {
      mobileMode: data,
    },
  };
};

export const updateGameSelected = (data) => {
  return {
    type: GET_GAME_SELECTED,
    payload: {
      gameSelected: data,
    },
  };
};

export const updateAllProduct = (data) => {
  return {
    type: GET_ALL_PRODUCT,
    payload: {
      allProduct: data,
    },
  };
};

export const updateIsLoading = (data) => {
  return {
    type: GET_IS_LOADING,
    payload: {
      isLoading: data,
    },
  };
};

export const updateUsdExchangeRate = (data) => {
  return {
    type: GET_USD_EXCHANGE_RATE,
    payload: {
      usdExchangeRate: data,
    },
  };
};
export const updateProductsSearch = (data) => {
  return {
    type: GET_PRODUCTS_SEARCH,
    payload: {
      productsSearch: data,
    },
  };
};

export const updateCollection = (data) => {
  return {
    type: GET_COLLECTION_SELECTED,
    payload: {
      collectionSelected: data,
    },
  };
};
export const updateTextSearch = (data) => {
  return {
    type: GET_TEXT_SEARCH,
    payload: {
      textSearch: data,
    },
  };
};
