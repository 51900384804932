import React, { useState } from "react";
import { convertCurrency } from "../Services/Func";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../redux/action/cartAction";
import { useToasts } from "react-toast-notifications";
import { useEffect } from "react";

const CardDetailElement = ({ item }) => {
  const { addToast } = useToasts();
  const imgFallback =
    "https://images.unsplash.com/photo-1612323272388-34fe470bedad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80";

  const dispatch = useDispatch();
  const [typeSelected, setTypeSelected] = useState("");
  const [stockData, setStockData] = useState([]);
  const cart = useSelector((state) => state.cartReducer.cart);

  const optionQty = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
  ];

  const [qtyRemain, setQtyRemain] = useState(0);

  const [optionsQty, setOptionsQty] = useState([]);

  const emptyOption = [
    {
      label: "out of stock",
      value: 0,
    },
  ];

  console.log("item", item);

  useEffect(() => {
    const stockObj = Object.keys(item.stock).map((el) => {
      return {
        type: el,
        stock: item.stock[el],
        price: item.price[el],
        cardSerial: item.cardSerial,
      };
    });

    const activeStock = stockObj.filter((item) => item.stock > 0);

    if (activeStock.length) {
      setStockData(activeStock);

      if (!typeSelected) {
        setTypeSelected(activeStock[0].type);

        const existing = cart.find(
          (p) =>
            p.cardSerial === item.cardSerial &&
            p.typeSelected === activeStock[0].stock
        );

        const getQty =
          activeStock[0].stock - (existing?.qty ?? 0) >= 0
            ? activeStock[0].stock - (existing?.qty ?? 0)
            : 0;

        setQtyRemain(getQty);
      } else {
        const activeStockWithType = activeStock.find(
          (el) => el.type === typeSelected
        );

        const existing = cart.find(
          (p) =>
            p.cardSerial === item.cardSerial &&
            p.typeSelected === activeStockWithType.stock
        );

        const getQty =
          activeStockWithType.stock - (existing?.qty ?? 0) >= 0
            ? activeStockWithType.stock - (existing?.qty ?? 0)
            : 0;

        setQtyRemain(getQty);
      }
    } else {
      setQtyRemain(0);
    }
  }, [item, typeSelected]);

  useEffect(() => {
    const newOptionQty = optionQty.slice(0, qtyRemain);

    setOptionsQty(newOptionQty);
    setQty(newOptionQty.length ? newOptionQty[0] : emptyOption);
  }, [qtyRemain]);

  const [qty, setQty] = useState();
  const history = useHistory();
  const styles = {
    image: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      margin: "auto",
      cursor: "pointer",
    },
    imageContainer: {
      width: "188px",
      height: "200px",
      margin: "auto",
      paddingTop: "10px",
    },
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      lineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    card: {
      borderRadius: "8px",
    },
    category: {
      color: "black",
      border: "1px solid #5581B3",
      padding: "10px",
    },
    textType: {
      fontSize: "10px",
      marginRight: "5px",
      marginBottom: "5px",
    },
  };

  const onSetQty = (ev) => {
    setQty(ev);
  };

  const onHandleAddCart = () => {
    const data = { ...item, qty: +qty.value, typeSelected };

    setQtyRemain(qtyRemain - +qty.value >= 0 ? qtyRemain - +qty.value : 0);

    dispatch(updateCart(data, cart));
    addToast("เพิ่มสินค้าลงตะกร้าแล้ว", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const onCardClick = () => {
    // history.push(`/product/${item._id}`);
    history.push(`/product/${item.cardSerial}`);
  };

  console.log("stockData", stockData);

  return (
    <div className="card" style={styles.card}>
      <div style={styles.imageContainer}>
        <img
          src={item.img || imgFallback}
          onClick={onCardClick}
          alt={item?.name}
          style={styles.image}
        />
      </div>
      <div className="card-body">
        <h5 className="card-title fw-bold" style={styles.text}>
          {item?.name}
        </h5>
        <p className="card-text" style={{ height: "20px", ...styles.text }}>
          {item?.gameEdition?.name}
        </p>
        <div>
          <div style={{ minHeight: "30px" }}>
            {stockData.map((el, tIndex) => {
              return (
                <>
                  <input
                    key={tIndex}
                    type="radio"
                    className="btn-check"
                    name={`options-type-${item.cardSerial}`}
                    id={`type-${el.type}-${item.cardSerial}`}
                    onClick={(ev) => setTypeSelected(ev.target.value)}
                    value={el.type}
                    defaultChecked={tIndex == 0 ? true : false}
                  />
                  <label
                    className="btn btn-sm btn-outline-secondary"
                    htmlFor={`type-${el.type}-${item.cardSerial}`}
                    style={styles.textType}
                  >
                    {el.type?.toUpperCase()}
                  </label>
                </>
              );
            })}
          </div>
        </div>
        <div className="my-2">
          <h2 className="fw-bold">
            {!isNaN(+item?.price[typeSelected])
              ? convertCurrency(+item?.price[typeSelected])
              : convertCurrency(0)}
          </h2>
        </div>
        <div className="my-2 row">
          <div className="col-5 pe-0">
            <Select
              options={optionsQty}
              onChange={onSetQty}
              value={qty}
              noOptionsMessage={() => "Out of stock"}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="col-7">
            <button
              className="btn button--primary h-100"
              style={{ fontSize: "14px" }}
              disabled={qty?.value > 0 ? false : true}
              onClick={onHandleAddCart}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetailElement;
