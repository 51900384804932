import React, { useState, useEffect } from "react";
import { convertCurrency } from "../Services/Func";
import Select from "react-select";
import { BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { removeCartItem, updateCart } from "../redux/action/cartAction";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const CartItem = ({ item, isCheckout }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartReducer.cart);

  const qtyRemain = item.stock[item.typeSelected];

  const [qty, setQty] = useState({ label: "0", value: "0" });

  const styles = {
    image: {
      width: "100%",

      objectFit: "contain",
    },
    imageContainer: {
      width: "100px",
      height: "128px",
      display: "flex",
    },
    title: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      lineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
  };

  let optionQty = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
  ];

  const onSetQty = (q) => {
    const data = { ...item, qty: q };

    dispatch(updateCart(data, cart));
  };

  const onRemoveItem = (data) => {
    dispatch(removeCartItem(data, cart));
  };

  return (
    <div className="row my-4">
      <div className="col-auto">
        <div style={styles.imageContainer}>
          <img src={item.img} alt={item.name} style={styles.image} />
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fw-bold" style={styles.title}>
            {item.name} ({item.typeSelected?.toUpperCase()})
          </div>
          <div className="btn" onClick={() => onRemoveItem(item)}>
            <BiTrash />
          </div>
        </div>
        <div>{item.category}</div>
        <div className="row">
          <div className="col">
            <div>{item?.optionalDetail?.rarity}</div>
          </div>
          <div className="col text-end">
            <div>
              {item?.price && item?.typeSelected
                ? convertCurrency(+item.price[item.typeSelected])
                : convertCurrency(0)}
            </div>
            <div>
              {isCheckout ? (
                <span>X {item.qty}</span>
              ) : (
                <div>
                  {item.qty > 1 && (
                    <AiOutlineMinus
                      type="button"
                      onClick={() => onSetQty(-1)}
                    />
                  )}
                  <span className="mx-3">{item.qty}</span>
                  {item.qty < qtyRemain && (
                    <AiOutlinePlus type="button" onClick={() => onSetQty(1)} />
                  )}
                </div>
              )}
              {item.canConfirm === false && !isCheckout ? (
                <div className="text-danger">Out of stock</div>
              ) : (
                ""
              )}
            </div>
            {/* remain : {qtyRemain}   */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
