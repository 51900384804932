import { combineReducers } from "redux";
import dataReducer from "./dataReducer";
import profileReducer from "./profileReducer";
import cartReducer from "./cartReducer";

const rootReducer = combineReducers({
  dataReducer,
  profileReducer,
  cartReducer,
});

export default rootReducer;
