import React, { useState, useEffect } from "react";
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeApi } from "../fakeApi/storeApi";
import {
  updateGameSelected,
  updateIsLoading,
} from "../redux/action/dataAction";
import CardDetailContainer from "../Components/HomeComponents/CardDetailContainer";
import AdvanceSearch from "./AdvanceSearch";
import Seal from "./Seal";
import Accessory from "./Accessory";
import Banner from "../Components/Banner";
import TopRoutePath from "../Components/TopRoutePath";
import { getProductPage } from "../Services/Crud";

const GameSelect = () => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const [card, setCard] = useState([]);
  const [seal, setSeal] = useState([]);
  const [accessory, setAccessory] = useState([]);

  let { gId } = useParams();

  let { path, url } = useRouteMatch();

  const styles = {
    bannerContainer: {
      width: "100vw",
      height: "580px",
    },
    bannerImg: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  };

  const onHandleGetAll = async (gameMasterId) => {
    dispatch(updateIsLoading(true));
    getProductPage(gameMasterId)
      .then((res) => {
        setCard(res.data.card);
        setSeal(res.data.seal);
        setAccessory(res.data.accessory);

        // dispatch(updateGameSelected(res.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(updateIsLoading(false));
      });
  };

  useEffect(() => {
    if (gId) {
      // onHandleGetAll("62893b464048140c7019367b");
      onHandleGetAll(gId);
    }
  }, [gId]);

  const displayGameExpand = (
    <>
      <Banner title={"Game"} />
      <div className="container-fluid">
        <CardDetailContainer
          title="New Avatar"
          products={card.slice(0, 6)}
          pathUrl={`${url}/advance`}
        />
        <CardDetailContainer
          title="Seal"
          products={seal.slice(0, 6)}
          pathUrl={`${url}/seal`}
        />
        <CardDetailContainer
          title="Accessory"
          products={accessory.slice(0, 6)}
          pathUrl={`${url}/accessory`}
        />
      </div>
    </>
  );

  return (
    <div>
      <TopRoutePath />
      <Switch>
        <Route exact path={path}>
          {displayGameExpand}
        </Route>
        <Route path={`${path}/advance`}>
          <AdvanceSearch data={card} />
        </Route>
        <Route path={`${path}/seal`}>
          <Seal data={seal} />
        </Route>
        <Route path={`${path}/accessory`}>
          <Accessory data={accessory} />
        </Route>
      </Switch>
    </div>
  );
};

export default GameSelect;
