import {
  GET_IS_MOBILE_MODE,
  GET_GAME_SELECTED,
  GET_ALL_PRODUCT,
  GET_IS_LOADING,
  GET_USD_EXCHANGE_RATE,
  GET_PRODUCTS_SEARCH,
  GET_COLLECTION_SELECTED,
  GET_TEXT_SEARCH,
} from "../action/dataAction";

const initState = {
  mobileMode: false,
  gameSelected: null,
  allProduct: [],
  isLoading: false,
  usdExchangeRate: 1,
  productsSearch: [],
  collectionSelected: "",
  textSearch: "",
};

const dataReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_IS_MOBILE_MODE:
      return {
        ...state,
        mobileMode: action.payload.mobileMode,
      };
    case GET_GAME_SELECTED:
      return {
        ...state,
        gameSelected: action.payload.gameSelected,
      };
    case GET_ALL_PRODUCT:
      return {
        ...state,
        allProduct: action.payload.allProduct,
      };

    case GET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case GET_USD_EXCHANGE_RATE:
      return {
        ...state,
        usdExchangeRate: action.payload.usdExchangeRate,
      };
    case GET_PRODUCTS_SEARCH:
      return {
        ...state,
        productsSearch: action.payload.productsSearch,
      };
    case GET_COLLECTION_SELECTED:
      return {
        ...state,
        collectionSelected: action.payload.collectionSelected,
      };
    case GET_TEXT_SEARCH:
      return {
        ...state,
        textSearch: action.payload.textSearch,
      };

    default:
      break;
  }

  return state;
};

export default dataReducer;
