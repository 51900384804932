import axios from "axios";

// export const baseURL = "http://localhost:8080";
// export const baseURL = "http://destinydraw.com/dd-api";
export const baseURL = process.env.REACT_APP_BASE_URL;

export const mtgApi = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

mtgApi.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem("dtnToken")}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

mtgApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("logout call");

      window.localStorage.removeItem("dtnToken");
      window.localStorage.removeItem("dtnProfile");
      window.alert("Please login");
      window.location.replace("/");
    }
    return error;
  }
);
