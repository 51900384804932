import React from "react";
import { useSelector } from "react-redux";

const MyProfile = () => {
  // const profile = useSelector((state) => state.profileReducer.profile);
  const profile = JSON.parse(localStorage.getItem("dtnProfile"));

  // const profile = localStorage.getItem("dtnProfile");
  // const profile = JSON.parse(localStorage.getItem("dtnProfile"));
  console.log({ profile });
  const styles = {
    textHeader: {
      fontSize: "20px",
      fontWeight: "600",
      color: "#414749",
    },
  };

  ///"/assets/img/user.png"

  return (
    <div className="card">
      <div className="card-body">
        <div style={styles.textHeader}>My Address</div>
        <div className="text-center">
          <div>{profile?.displayName}</div>
        </div>

        <div className="row my-3 g-2">
          <div className="col-6">
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              defaultValue={profile?.displayName?.split(" ")[0]}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              defaultValue={profile?.displayName?.split(" ")[1]}
            />
          </div>
          <div className="col-6">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              defaultValue={profile?.email}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Phone Number"
            />
          </div>
        </div>

        <div className="text-end">
          <button className="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
