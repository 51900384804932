import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "./Layout/MainLayout";
import Home from "./Pages/Home";
import GameSelect from "./Pages/GameSelect";
import AdvanceSearch from "./Pages/AdvanceSearch";
import ProductDetail from "./Pages/ProductDetail";
import Checkout from "./Pages/Checkout";
import MyProfile from "./Pages/MyProfile";
import MyAddress from "./Pages/MyAddress";
import MyOrders from "./Pages/MyOrders";
import ProfileLayout from "./Layout/ProfileLayout";
import OrderDetail from "./Pages/OrderDetail";
import { updateProfile, updateToken } from "./redux/action/profileAction";
import jwt_decode from "jwt-decode";
import { mtgApi } from "./api/mtgAPI";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Layout>
            <Component {...props}></Component>
          </Layout>
        );
      }}
    ></Route>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const dtnToken = window.localStorage.getItem("dtnToken");

  mtgApi.defaults.headers.common["Authorization"] = `Bearer ${dtnToken}`;

  useEffect(async () => {
    const url = new URL(window.location.href);
    let tk = url.searchParams.get("token");

    if (tk) {
      // window.localStorage.setItem("dtnToken", tk);
      window.history.pushState({}, document.title, url.origin);

      const userDetail = await jwt_decode(tk);

      window.localStorage.setItem("dtnProfile", JSON.stringify(userDetail));
      window.localStorage.setItem("dtnToken", tk);
      dispatch(updateProfile(userDetail));
      dispatch(updateToken(tk));
    }
  }, []);

  return (
    <Switch>
      <AppRoute path="/" exact layout={MainLayout} component={Home} />
      <AppRoute path="/game/:gId" layout={MainLayout} component={GameSelect} />
      <AppRoute
        path="/product/:pId"
        layout={MainLayout}
        component={ProductDetail}
      />
      <AppRoute path="/checkout" layout={MainLayout} component={Checkout} />
      <AppRoute
        path="/order/:orderNo"
        layout={MainLayout}
        component={OrderDetail}
      />

      <AppRoute
        path="/myprofile"
        layout={ProfileLayout}
        component={MyProfile}
      />
      <AppRoute
        path="/myaddress"
        layout={ProfileLayout}
        component={MyAddress}
      />
      <AppRoute path="/myorders" layout={ProfileLayout} component={MyOrders} />
      <AppRoute path="*" layout={MainLayout} component={<h1>Not Found</h1>} />
    </Switch>
  );
};

export default App;
