import React from "react";
import { Link } from "react-router-dom";
import CardPreview from "../Elements/CardPreview";

const CardContainer = ({ itemWidth, itemHeight, textTitle, products }) => {
  const styles = {
    itemDimension: {
      height: itemHeight,
      width: itemWidth,
      margin: "auto",
      borderRadius: "8px",
      border: "1px solid rgba(20,20,20,0.1)",
    },
    textTitle: {
      color: "#414749",
      fontSize: "34px",
      fontWeight: "500",
    },
  };

  return (
    <div className="my-5">
      <div>
        <span style={styles.textTitle}>{textTitle}</span>
      </div>
      <div className="row g-2">
        {products.length
          ? products.map((item, index) => {
              return (
                <div key={index} className="col">
                  <div style={styles.itemDimension}>
                    <Link to={`/game/${item._id}`}>
                      <CardPreview item={item} />
                    </Link>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default CardContainer;
