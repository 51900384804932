import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { HiOutlineUser, HiOutlineShoppingBag } from "react-icons/hi";

const ProfileLayout = ({ children }) => {
  const history = useHistory();
  const { pathname } = history.location;

  const styles = {
    headMenu: {
      color: "#414749",
      fontSize: "20px",
      fontWeight: "600",
      margin: "10px 20px",
    },
    container: {
      lineHeight: "3",
      marginBottom: "20px",
    },
  };

  const stylesFn = (menuPath) => {
    return {
      paddingInline: "20px",
      fontSize: "16px",
      fontWeight: "400",
      listStyle: "none",
      color:
        pathname == menuPath
          ? "rgba(65, 71, 73, 1)"
          : "rgba(66, 82, 110, 0.86)",
      backgroundColor: pathname == menuPath ? "rgba(76, 104, 153, 0.06)" : "",
      borderLeft: pathname == menuPath ? "5px solid rgba(85, 129, 179, 1)" : "",
    };
  };

  const displayMenu = (
    <div className="card">
      <div style={styles.container}>
        <div>
          <div style={styles.headMenu}>Manage Account</div>
          <Link to="/myprofile">
            <li style={stylesFn("/myprofile")}>
              <HiOutlineUser size="1.5rem" /> My Profile
            </li>
          </Link>
          <Link to="/myaddress">
            <li style={stylesFn("/myaddress")}>
              <AiOutlineHome size="1.5rem" /> My Address
            </li>
          </Link>
        </div>
        <div>
          <div style={styles.headMenu}>My Orders</div>
          <Link to="/myorders">
            <li style={stylesFn("/myorders")}>
              <HiOutlineShoppingBag size="1.5rem" /> My Orders
            </li>
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="position-relative " style={{ minHeight: "100vh" }}>
        <Header />
        <div className="container my-4">
          <div className="row">
            <div className="col-3">{displayMenu}</div>
            <div className="col-9">{children}</div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ProfileLayout;
