import React, { useState, useEffect } from "react";
import ProductsOrdered from "../Components/ProductsOrdered";
import { convertCurrency, readFileDataTo64 } from "../Services/Func";
import { useParams } from "react-router-dom";
import { fakeOrder, storeApi } from "../fakeApi/storeApi";
import { BsChevronLeft } from "react-icons/bs";
import { HiOutlineHome, HiOutlineDocumentText } from "react-icons/hi";
import BadgeEl from "../Elements/BadgeEl";
import {
  getAListOrderDetail,
  getUserOrderDetail,
  postUploadSlip,
} from "../Services/Crud";
import OrderDetailEl from "../Components/OrderDetailEl";
import { useToasts } from "react-toast-notifications";

const OrderDetail = () => {
  let { orderNo } = useParams();
  const { addToast } = useToasts();
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderAddress, setOrderAddress] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);

  const [slipImage, setSlipImage] = useState(null);
  const [allShipped, setAllShipped] = useState(false);
  const [address, setAddress] = useState(null);
  const [aListOrder, setAListOrder] = useState(null);

  const styles = {
    bankImg: {
      width: "35px",
      height: "35px",
      objectFit: "contain",
      marginRight: "5px",
    },
    tableTr: {
      height: "80px",
    },
    slipImage: {
      height: "336px",
      objectFit: "contain",
    },
    imgInTable: {
      width: "78px",
      height: "100px",
      objectFit: "contain",
      marginRight: "5px",
      padding: "5px",
    },
    tableTitle: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      lineClamp: 1,
      WebkitBoxOrient: "vertical",
      fontWeight: "600",
      fontSize: "16px",
    },
  };

  const optionsPayment = [
    {
      bankName: "Kbank",
      accountNumber: "123564789",
      accountName: "Company Name",
      imgPath: "/assets/img/kbank.png",
    },
    {
      bankName: "Bay",
      accountNumber: "123564789",
      accountName: "Company Name",
      imgPath: "/assets/img/bay.png",
    },
    {
      bankName: "BBL",
      accountNumber: "123564789",
      accountName: "Company Name",
      imgPath: "/assets/img/bbl.png",
    },
    {
      bankName: "SCB",
      accountNumber: "123564789",
      accountName: "Company Name",
      imgPath: "/assets/img/scb.png",
    },
    {
      bankName: "KK",
      accountNumber: "123564789",
      accountName: "Company Name",
      imgPath: "/assets/img/kk.png",
    },
    {
      bankName: "TTB",
      accountNumber: "123564789",
      accountName: "Company Name",
      imgPath: "/assets/img/ttb.png",
    },
  ];

  // const getAListOrderDetailFn = async () => {
  //   console.log("calling");
  //   await getAListOrderDetail(orderNo)
  //     .then((res) => {
  //       const { data } = res.data;
  //       console.log("alist", res);
  //       setAListOrder(data);
  //       // setPaymentSlip(res.data.paymentSlip);
  //       setAddress(res.data.address);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getOrderDetail = () => {
    getUserOrderDetail(orderNo)
      .then((res) => {
        console.log("getOrderDetail", { res });
        setOrderDetail(res.data);
        setOrderAddress(res.address);
        setOrderNumber(res.orderNo);

        const as = res.data.every((el) => el.isDeliver);

        setAllShipped(as);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (orderNo) {
      getOrderDetail();
      // getAListOrderDetailFn();
    }
  }, [orderNo]);

  const onInputImage = async (ev) => {
    const file = await ev.target.files[0];
    const img64 = await readFileDataTo64(file);

    setSlipImage(img64);

    postUploadSlip(orderNo, img64)
      .then((res) => {
        console.log(res.data);

        addToast(res?.message ?? "Successfully uploaded", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast(err?.message ?? "Failed to upload", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const displayPaymentMethod = (
    <div>
      <div className="H6">Payment Method</div>
      <div className="my-3">
        <table className="w-100">
          <thead>
            <tr>
              <th>Bank</th>
              <th>Account Number</th>
              <th>Account Name</th>
            </tr>
          </thead>
          <tbody>
            {!!optionsPayment.length &&
              optionsPayment.map((item, index) => {
                return (
                  <tr style={styles.tableTr} key={index}>
                    <th>
                      <img
                        src={item.imgPath}
                        alt={item.bankName}
                        style={styles.bankImg}
                      />
                      {item.bankName}
                    </th>
                    <td>{item.accountNumber}</td>
                    <td>{item.accountName}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );

  const displayUploadImage = (
    <div>
      <div className="H6">Upload Image</div>
      <div className="text-center my-3">
        {slipImage && (
          <img src={slipImage} alt="slip" style={styles.slipImage} />
        )}
      </div>
      <div>
        <label className="label-upload">
          + Upload Image
          <input
            className=""
            type="file"
            accept="image/*"
            id="input-file"
            onChange={onInputImage}
          />
        </label>
      </div>

      {/* <div className="row my-3 g-3">
        <div className="col-6">
          <input
            type="date"
            className="form-control"
            placeholder="Date transfer"
          />
        </div>
        <div className="col-6">
          <input
            type="time"
            className="form-control"
            placeholder="Time transfer"
          />
        </div>
        <div className="col-6">
          <input
            type="email"
            className="form-control"
            placeholder="Bank transfer"
          />
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder="Accout transfer"
          />
        </div>
      </div> */}
    </div>
  );

  const displayTable = (
    <div className="card">
      <div className="card-body">
        <table className="table w-100">
          <thead>
            <tr>
              <th>Product</th>
              <th>Tracking No.</th>
              {/* <th>SKU</th> */}
              <th>Quantity</th>
              <th>Price</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>
            {orderDetail.length
              ? orderDetail.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="d-flex" style={{ maxWidth: "300px" }}>
                          <img
                            src={item?.card?.img}
                            alt={item?.card?.name}
                            style={styles.imgInTable}
                          />
                          <div>
                            <div style={styles.tableTitle}>
                              {item?.card?.name}
                            </div>

                            <div>{item?.card?.optionalDetail?.rarity}</div>
                          </div>
                        </div>
                      </td>
                      <td>{item.trackingNo}</td>
                      {/* <td>{item.card.cardSerial}</td> */}
                      <td>X {item.amount}</td>
                      <td>{convertCurrency(item.price)}</td>
                      <td>{convertCurrency(item.summary)}</td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );

  const displayOrderConfirmed = (
    <>
      <div className="H4">Order Detail</div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="H6">Order Number #{orderNo}</div>
        <div className="H6" style={{ color: "rgba(87, 240, 0, 1)" }}>
          Completed
        </div>
      </div>
      <div className="row my-2">
        <div className="col-6">
          <BadgeEl
            title="Delivery Address"
            icon={<HiOutlineHome size="1.5rem" color="white" />}
            fullName={`${orderAddress?.user?.firstName} ${orderAddress?.user?.lastName}`}
            // trackingNo="12345"
            address={`${orderAddress?.etc} ${orderAddress?.subDistinct} ${orderAddress?.distinct} ${orderAddress?.postcode}`}
            phone={orderAddress?.phoneNo}
          />
        </div>
        <div className="col-6">
          <BadgeEl
            title="Billing Address"
            icon={<HiOutlineDocumentText size="1.5rem" color="white" />}
            fullName={`${orderAddress?.user?.firstName} ${orderAddress?.user?.lastName}`}
            address={`${orderAddress?.etc} ${orderAddress?.subDistinct} ${orderAddress?.distinct} ${orderAddress?.postcode}`}
            phone={orderAddress?.phoneNo}
          />
        </div>
      </div>
      {displayTable}
    </>
  );

  const displayOrderPending = (
    <>
      <div className="H4">Order Number #{orderNumber}</div>
      <div className="row my-2 g-2">
        <div className="col-md-7">
          <div className="card">
            <div className="card-body">
              {displayPaymentMethod}
              {displayUploadImage}
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <OrderDetailEl orderDetail={orderDetail} />
        </div>
      </div>
    </>
  );

  return (
    <div className="container my-4">
      <div>
        {orderDetail && allShipped
          ? displayOrderConfirmed
          : displayOrderPending}
      </div>
    </div>
  );
};

export default OrderDetail;
