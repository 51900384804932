import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducer/rootReducer";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const myStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
  });
  const persistor = persistStore(store);
  return { store, persistor };
};
export default myStore;
