import axios from "axios";

export const storeApi = axios.create({
  baseURL: "https://fakestoreapi.com/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const fakeOrder = [
  {
    _id: "62b1f443ce41d1149747cca8",
    amount: 1,
    price: 100,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    order: {
      isPayment: false,
      _id: "62b1f443ce41d1149747cca6",
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      summary: 100,
      shipping: 50,
      total: 150,
      orderNo: "2022-06-21-10010",
      createdAt: "2022-06-21T16:39:31.315Z",
      updatedAt: "2022-06-21T16:39:31.315Z",
      user: "62c13542282d443c81ce700c",
    },
    createdAt: "2022-06-21T16:39:31.330Z",
    updatedAt: "2022-06-21T16:39:31.330Z",
  },
  {
    _id: "62b1f443ce41d1149747cca9",
    amount: 2,
    price: 50,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    order: {
      isPayment: false,
      _id: "62b1f443ce41d1149747cca6",
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      summary: 100,
      shipping: 50,
      total: 150,
      orderNo: "2022-06-21-10010",
      createdAt: "2022-06-21T16:39:31.315Z",
      updatedAt: "2022-06-21T16:39:31.315Z",
      user: "62c13542282d443c81ce700c",
    },
    createdAt: "2022-06-21T16:39:31.330Z",
    updatedAt: "2022-06-21T16:39:31.330Z",
  },
  {
    _id: "62b1f4a6959d9f14f49de705",
    amount: 1,
    price: 100,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    order: {
      isPayment: false,
      _id: "62b1f4a6959d9f14f49de703",
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      summary: 100,
      shipping: 50,
      total: 150,
      orderNo: "2022-06-21-10010",
      createdAt: "2022-06-21T16:41:10.376Z",
      updatedAt: "2022-06-21T16:41:10.376Z",
    },
    createdAt: "2022-06-21T16:41:10.385Z",
    updatedAt: "2022-06-21T16:41:10.385Z",
  },
  {
    _id: "62b1f4a6959d9f14f49de706",
    amount: 2,
    price: 50,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    order: {
      isPayment: false,
      _id: "62b1f4a6959d9f14f49de703",
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      summary: 100,
      shipping: 50,
      total: 150,
      orderNo: "2022-06-21-10010",
      createdAt: "2022-06-21T16:41:10.376Z",
      updatedAt: "2022-06-21T16:41:10.376Z",
    },
    createdAt: "2022-06-21T16:41:10.386Z",
    updatedAt: "2022-06-21T16:41:10.386Z",
  },
  {
    _id: "62b4748f959d9f14f49de716",
    amount: 1,
    price: 100,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    order: {
      isPayment: false,
      _id: "62b4748f959d9f14f49de714",
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      summary: 100,
      shipping: 50,
      total: 150,
      orderNo: "2022-06-23-10010",
      createdAt: "2022-06-23T14:11:27.076Z",
      updatedAt: "2022-06-23T14:11:27.076Z",
    },
    createdAt: "2022-06-23T14:11:27.259Z",
    updatedAt: "2022-06-23T14:11:27.259Z",
  },
  {
    _id: "62b4748f959d9f14f49de717",
    amount: 2,
    price: 50,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    order: {
      isPayment: false,
      _id: "62b4748f959d9f14f49de714",
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      summary: 100,
      shipping: 50,
      total: 150,
      orderNo: "2022-06-23-10010",
      createdAt: "2022-06-23T14:11:27.076Z",
      updatedAt: "2022-06-23T14:11:27.076Z",
    },
    createdAt: "2022-06-23T14:11:27.261Z",
    updatedAt: "2022-06-23T14:11:27.261Z",
  },
  {
    _id: "62c08fc25d24c6857bd5c41f",
    order: {
      isPayment: false,
      _id: "62c08fc25d24c6857bd5c41d",
      orderNo: "2022-07-02-10010",
      summary: 100,
      total: 150,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      createdAt: "2022-07-02T18:34:42.739Z",
      updatedAt: "2022-07-02T18:34:42.739Z",
    },
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    amount: 1,
    price: 100,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-07-02T18:34:42.793Z",
    updatedAt: "2022-07-02T18:34:42.793Z",
  },
  {
    _id: "62c08fc25d24c6857bd5c420",
    order: {
      isPayment: false,
      _id: "62c08fc25d24c6857bd5c41d",
      orderNo: "2022-07-02-10010",
      summary: 100,
      total: 150,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      createdAt: "2022-07-02T18:34:42.739Z",
      updatedAt: "2022-07-02T18:34:42.739Z",
    },
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    amount: 2,
    price: 50,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-07-02T18:34:42.793Z",
    updatedAt: "2022-07-02T18:34:42.793Z",
  },
  {
    _id: "62c09154e44e8103a7d4be05",
    order: {
      isPayment: false,
      _id: "62c09154e44e8103a7d4be03",
      orderNo: "2022-07-02-10010",
      summary: 100,
      total: 150,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      createdAt: "2022-07-02T18:41:24.617Z",
      updatedAt: "2022-07-02T18:41:24.617Z",
    },
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    amount: 1,
    price: 100,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-07-02T18:41:24.628Z",
    updatedAt: "2022-07-02T18:41:24.628Z",
  },
  {
    _id: "62c09154e44e8103a7d4be06",
    order: {
      isPayment: false,
      _id: "62c09154e44e8103a7d4be03",
      orderNo: "2022-07-02-10010",
      summary: 100,
      total: 150,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      createdAt: "2022-07-02T18:41:24.617Z",
      updatedAt: "2022-07-02T18:41:24.617Z",
    },
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    amount: 2,
    price: 50,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-07-02T18:41:24.628Z",
    updatedAt: "2022-07-02T18:41:24.628Z",
  },
  {
    _id: "630776be38227cfe2f0c6d34",
    order: {
      _id: "630776be38227cfe2f0c6d32",
      orderNo: "2022-08-25-10010",
      summary: 100,
      total: 150,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:18:54.023Z",
      updatedAt: "2022-08-25T13:18:54.023Z",
    },
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    amount: 1,
    price: 100,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:18:54.033Z",
    updatedAt: "2022-08-25T13:18:54.033Z",
  },
  {
    _id: "630776be38227cfe2f0c6d35",
    order: {
      _id: "630776be38227cfe2f0c6d32",
      orderNo: "2022-08-25-10010",
      summary: 100,
      total: 150,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:18:54.023Z",
      updatedAt: "2022-08-25T13:18:54.023Z",
    },
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    amount: 2,
    price: 50,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:18:54.033Z",
    updatedAt: "2022-08-25T13:18:54.033Z",
  },
  {
    _id: "630777ab38227cfe2f0c6d49",
    order: {
      _id: "630777ab38227cfe2f0c6d47",
      orderNo: "2022-08-25-10010",
      summary: 50,
      total: 0,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:22:51.309Z",
      updatedAt: "2022-08-25T13:22:51.309Z",
    },
    card: {
      _id: "628b3df87716a164d30ae852",
      optionalDetail: [
        {
          object: "card",
          released_at: "2009-09-04",
          layout: "planar",
          image_uris: {
            small:
              "https://c1.scryfall.com/file/scryfall-cards/small/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            normal:
              "https://c1.scryfall.com/file/scryfall-cards/normal/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            large:
              "https://c1.scryfall.com/file/scryfall-cards/large/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            png: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.png?1547432241",
            art_crop:
              "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            border_crop:
              "https://c1.scryfall.com/file/scryfall-cards/border_crop/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
          },
          mana_cost: "",
          cmc: 0,
          type_line: "Plane — Luvion",
          oracle_text:
            "Creatures without flying or islandwalk can't attack.\nWhenever you roll {CHAOS}, until a player planeswalks, you can't lose the game and your opponents can't win the game.",
          colors: [],
          color_identity: [],
          games: ["paper"],
          foil: false,
          nonfoil: true,
          finishes: ["nonfoil"],
          oversized: true,
          variation: false,
          set: "phop",
          set_name: "Promotional Planes",
          set_type: "promo",
          collector_number: "42",
          digital: false,
          rarity: "rare",
          promo_types: ["instore"],
        },
      ],
      isDelete: false,
      name: "Celestine Reef",
      detail:
        "Creatures without flying or islandwalk can't attack.\nWhenever you roll {CHAOS}, until a player planeswalks, you can't lose the game and your opponents can't win the game.",
      price: {
        usd: "0.52",
        usd_foil: null,
        usd_etched: null,
        eur: "1.45",
        eur_foil: null,
        tix: null,
      },
      cardSerial: "37957",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.png?1547432241",
      gameEdition: "628a86ae0971793aeec9deb3",
      stock: 10,
      createdAt: "2022-05-23T07:55:36.130Z",
      updatedAt: "2022-05-23T08:03:56.908Z",
      id: "628b3df87716a164d30ae852",
    },
    amount: 1,
    price: 0.52,
    summary: 0.52,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:22:51.314Z",
    updatedAt: "2022-08-25T13:22:51.314Z",
  },
  {
    _id: "630777ab38227cfe2f0c6d4a",
    order: {
      _id: "630777ab38227cfe2f0c6d47",
      orderNo: "2022-08-25-10010",
      summary: 50,
      total: 0,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:22:51.309Z",
      updatedAt: "2022-08-25T13:22:51.309Z",
    },
    card: {
      _id: "628b3df87716a164d30ae850",
      optionalDetail: [
        {
          object: "card",
          released_at: "1997-03-24",
          layout: "normal",
          image_uris: {
            small:
              "https://c1.scryfall.com/file/scryfall-cards/small/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            normal:
              "https://c1.scryfall.com/file/scryfall-cards/normal/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            large:
              "https://c1.scryfall.com/file/scryfall-cards/large/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            png: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.png?1616251341",
            art_crop:
              "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            border_crop:
              "https://c1.scryfall.com/file/scryfall-cards/border_crop/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
          },
          mana_cost: "{U}{U}",
          cmc: 2,
          type_line: "Instant",
          oracle_text: "Return target permanent to its owner's hand.",
          colors: ["U"],
          color_identity: ["U"],
          games: ["paper"],
          foil: false,
          nonfoil: true,
          finishes: ["nonfoil"],
          oversized: false,
          variation: false,
          set: "5ed",
          set_name: "Fifth Edition",
          set_type: "core",
          collector_number: "75s",
          digital: false,
          rarity: "common",
        },
      ],
      isDelete: false,
      name: "Boomerang",
      detail: "Return target permanent to its owner's hand.",
      price: {
        usd: "0.17",
        usd_foil: null,
        usd_etched: null,
        eur: "0.02",
        eur_foil: null,
        tix: null,
      },
      cardSerial: "2054",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.png?1616251341",
      gameEdition: "628a86b00971793aeec9e059",
      stock: 10,
      createdAt: "2022-05-23T07:55:36.126Z",
      updatedAt: "2022-05-23T08:00:16.258Z",
      id: "628b3df87716a164d30ae850",
    },
    amount: 1,
    price: 0.17,
    summary: 0.17,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:22:51.314Z",
    updatedAt: "2022-08-25T13:22:51.314Z",
  },
  {
    _id: "63077ad838227cfe2f0c6db1",
    order: {
      _id: "63077ad838227cfe2f0c6daf",
      orderNo: "2022-08-25-10010",
      summary: 50,
      total: 0,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:36:24.937Z",
      updatedAt: "2022-08-25T13:36:24.937Z",
    },
    card: {
      _id: "628b3df87716a164d30ae852",
      optionalDetail: [
        {
          object: "card",
          released_at: "2009-09-04",
          layout: "planar",
          image_uris: {
            small:
              "https://c1.scryfall.com/file/scryfall-cards/small/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            normal:
              "https://c1.scryfall.com/file/scryfall-cards/normal/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            large:
              "https://c1.scryfall.com/file/scryfall-cards/large/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            png: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.png?1547432241",
            art_crop:
              "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            border_crop:
              "https://c1.scryfall.com/file/scryfall-cards/border_crop/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
          },
          mana_cost: "",
          cmc: 0,
          type_line: "Plane — Luvion",
          oracle_text:
            "Creatures without flying or islandwalk can't attack.\nWhenever you roll {CHAOS}, until a player planeswalks, you can't lose the game and your opponents can't win the game.",
          colors: [],
          color_identity: [],
          games: ["paper"],
          foil: false,
          nonfoil: true,
          finishes: ["nonfoil"],
          oversized: true,
          variation: false,
          set: "phop",
          set_name: "Promotional Planes",
          set_type: "promo",
          collector_number: "42",
          digital: false,
          rarity: "rare",
          promo_types: ["instore"],
        },
      ],
      isDelete: false,
      name: "Celestine Reef",
      detail:
        "Creatures without flying or islandwalk can't attack.\nWhenever you roll {CHAOS}, until a player planeswalks, you can't lose the game and your opponents can't win the game.",
      price: {
        usd: "0.52",
        usd_foil: null,
        usd_etched: null,
        eur: "1.45",
        eur_foil: null,
        tix: null,
      },
      cardSerial: "37957",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.png?1547432241",
      gameEdition: "628a86ae0971793aeec9deb3",
      stock: 10,
      createdAt: "2022-05-23T07:55:36.130Z",
      updatedAt: "2022-05-23T08:03:56.908Z",
      id: "628b3df87716a164d30ae852",
    },
    amount: 1,
    price: 0.52,
    summary: 0.52,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:36:24.947Z",
    updatedAt: "2022-08-25T13:36:24.947Z",
  },
  {
    _id: "63077ad838227cfe2f0c6db2",
    order: {
      _id: "63077ad838227cfe2f0c6daf",
      orderNo: "2022-08-25-10010",
      summary: 50,
      total: 0,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:36:24.937Z",
      updatedAt: "2022-08-25T13:36:24.937Z",
    },
    card: {
      _id: "628b3df87716a164d30ae850",
      optionalDetail: [
        {
          object: "card",
          released_at: "1997-03-24",
          layout: "normal",
          image_uris: {
            small:
              "https://c1.scryfall.com/file/scryfall-cards/small/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            normal:
              "https://c1.scryfall.com/file/scryfall-cards/normal/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            large:
              "https://c1.scryfall.com/file/scryfall-cards/large/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            png: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.png?1616251341",
            art_crop:
              "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            border_crop:
              "https://c1.scryfall.com/file/scryfall-cards/border_crop/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
          },
          mana_cost: "{U}{U}",
          cmc: 2,
          type_line: "Instant",
          oracle_text: "Return target permanent to its owner's hand.",
          colors: ["U"],
          color_identity: ["U"],
          games: ["paper"],
          foil: false,
          nonfoil: true,
          finishes: ["nonfoil"],
          oversized: false,
          variation: false,
          set: "5ed",
          set_name: "Fifth Edition",
          set_type: "core",
          collector_number: "75s",
          digital: false,
          rarity: "common",
        },
      ],
      isDelete: false,
      name: "Boomerang",
      detail: "Return target permanent to its owner's hand.",
      price: {
        usd: "0.17",
        usd_foil: null,
        usd_etched: null,
        eur: "0.02",
        eur_foil: null,
        tix: null,
      },
      cardSerial: "2054",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.png?1616251341",
      gameEdition: "628a86b00971793aeec9e059",
      stock: 10,
      createdAt: "2022-05-23T07:55:36.126Z",
      updatedAt: "2022-05-23T08:00:16.258Z",
      id: "628b3df87716a164d30ae850",
    },
    amount: 1,
    price: 0.17,
    summary: 0.17,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:36:24.947Z",
    updatedAt: "2022-08-25T13:36:24.947Z",
  },
  {
    _id: "63077dde38227cfe2f0c6ddf",
    order: {
      _id: "63077dde38227cfe2f0c6ddd",
      orderNo: "2022-08-25-10010",
      summary: 50,
      total: 0,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:49:18.112Z",
      updatedAt: "2022-08-25T13:49:18.112Z",
    },
    card: {
      _id: "628b3df87716a164d30ae852",
      optionalDetail: [
        {
          object: "card",
          released_at: "2009-09-04",
          layout: "planar",
          image_uris: {
            small:
              "https://c1.scryfall.com/file/scryfall-cards/small/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            normal:
              "https://c1.scryfall.com/file/scryfall-cards/normal/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            large:
              "https://c1.scryfall.com/file/scryfall-cards/large/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            png: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.png?1547432241",
            art_crop:
              "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            border_crop:
              "https://c1.scryfall.com/file/scryfall-cards/border_crop/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
          },
          mana_cost: "",
          cmc: 0,
          type_line: "Plane — Luvion",
          oracle_text:
            "Creatures without flying or islandwalk can't attack.\nWhenever you roll {CHAOS}, until a player planeswalks, you can't lose the game and your opponents can't win the game.",
          colors: [],
          color_identity: [],
          games: ["paper"],
          foil: false,
          nonfoil: true,
          finishes: ["nonfoil"],
          oversized: true,
          variation: false,
          set: "phop",
          set_name: "Promotional Planes",
          set_type: "promo",
          collector_number: "42",
          digital: false,
          rarity: "rare",
          promo_types: ["instore"],
        },
      ],
      isDelete: false,
      name: "Celestine Reef",
      detail:
        "Creatures without flying or islandwalk can't attack.\nWhenever you roll {CHAOS}, until a player planeswalks, you can't lose the game and your opponents can't win the game.",
      price: {
        usd: "0.52",
        usd_foil: null,
        usd_etched: null,
        eur: "1.45",
        eur_foil: null,
        tix: null,
      },
      cardSerial: "37957",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.png?1547432241",
      gameEdition: "628a86ae0971793aeec9deb3",
      stock: 10,
      createdAt: "2022-05-23T07:55:36.130Z",
      updatedAt: "2022-05-23T08:03:56.908Z",
      id: "628b3df87716a164d30ae852",
    },
    amount: 1,
    price: 0.52,
    summary: 0.52,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:49:18.120Z",
    updatedAt: "2022-08-25T13:49:18.120Z",
  },
  {
    _id: "63077dde38227cfe2f0c6de0",
    order: {
      _id: "63077dde38227cfe2f0c6ddd",
      orderNo: "2022-08-25-10010",
      summary: 50,
      total: 0,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:49:18.112Z",
      updatedAt: "2022-08-25T13:49:18.112Z",
    },
    card: {
      _id: "628b3df87716a164d30ae850",
      optionalDetail: [
        {
          object: "card",
          released_at: "1997-03-24",
          layout: "normal",
          image_uris: {
            small:
              "https://c1.scryfall.com/file/scryfall-cards/small/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            normal:
              "https://c1.scryfall.com/file/scryfall-cards/normal/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            large:
              "https://c1.scryfall.com/file/scryfall-cards/large/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            png: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.png?1616251341",
            art_crop:
              "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            border_crop:
              "https://c1.scryfall.com/file/scryfall-cards/border_crop/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
          },
          mana_cost: "{U}{U}",
          cmc: 2,
          type_line: "Instant",
          oracle_text: "Return target permanent to its owner's hand.",
          colors: ["U"],
          color_identity: ["U"],
          games: ["paper"],
          foil: false,
          nonfoil: true,
          finishes: ["nonfoil"],
          oversized: false,
          variation: false,
          set: "5ed",
          set_name: "Fifth Edition",
          set_type: "core",
          collector_number: "75s",
          digital: false,
          rarity: "common",
        },
      ],
      isDelete: false,
      name: "Boomerang",
      detail: "Return target permanent to its owner's hand.",
      price: {
        usd: "0.17",
        usd_foil: null,
        usd_etched: null,
        eur: "0.02",
        eur_foil: null,
        tix: null,
      },
      cardSerial: "2054",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.png?1616251341",
      gameEdition: "628a86b00971793aeec9e059",
      stock: 10,
      createdAt: "2022-05-23T07:55:36.126Z",
      updatedAt: "2022-05-23T08:00:16.258Z",
      id: "628b3df87716a164d30ae850",
    },
    amount: 1,
    price: 0.17,
    summary: 0.17,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:49:18.120Z",
    updatedAt: "2022-08-25T13:49:18.120Z",
  },
  {
    _id: "63077ead38227cfe2f0c6df6",
    order: {
      _id: "63077ead38227cfe2f0c6df4",
      orderNo: "2022-08-25-10010",
      summary: 50,
      total: 0,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:52:45.032Z",
      updatedAt: "2022-08-25T13:52:45.032Z",
    },
    card: {
      _id: "628b3df87716a164d30ae852",
      optionalDetail: [
        {
          object: "card",
          released_at: "2009-09-04",
          layout: "planar",
          image_uris: {
            small:
              "https://c1.scryfall.com/file/scryfall-cards/small/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            normal:
              "https://c1.scryfall.com/file/scryfall-cards/normal/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            large:
              "https://c1.scryfall.com/file/scryfall-cards/large/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            png: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.png?1547432241",
            art_crop:
              "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
            border_crop:
              "https://c1.scryfall.com/file/scryfall-cards/border_crop/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.jpg?1547432241",
          },
          mana_cost: "",
          cmc: 0,
          type_line: "Plane — Luvion",
          oracle_text:
            "Creatures without flying or islandwalk can't attack.\nWhenever you roll {CHAOS}, until a player planeswalks, you can't lose the game and your opponents can't win the game.",
          colors: [],
          color_identity: [],
          games: ["paper"],
          foil: false,
          nonfoil: true,
          finishes: ["nonfoil"],
          oversized: true,
          variation: false,
          set: "phop",
          set_name: "Promotional Planes",
          set_type: "promo",
          collector_number: "42",
          digital: false,
          rarity: "rare",
          promo_types: ["instore"],
        },
      ],
      isDelete: false,
      name: "Celestine Reef",
      detail:
        "Creatures without flying or islandwalk can't attack.\nWhenever you roll {CHAOS}, until a player planeswalks, you can't lose the game and your opponents can't win the game.",
      price: {
        usd: "0.52",
        usd_foil: null,
        usd_etched: null,
        eur: "1.45",
        eur_foil: null,
        tix: null,
      },
      cardSerial: "37957",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffcbc4e-c6dc-4808-b262-f7c453e74dd8.png?1547432241",
      gameEdition: "628a86ae0971793aeec9deb3",
      stock: 10,
      createdAt: "2022-05-23T07:55:36.130Z",
      updatedAt: "2022-05-23T08:03:56.908Z",
      id: "628b3df87716a164d30ae852",
    },
    amount: 1,
    price: 0.52,
    summary: 0.52,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:52:45.037Z",
    updatedAt: "2022-08-25T13:52:45.037Z",
  },
  {
    _id: "63077ead38227cfe2f0c6df7",
    order: {
      _id: "63077ead38227cfe2f0c6df4",
      orderNo: "2022-08-25-10010",
      summary: 50,
      total: 0,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      createdAt: "2022-08-25T13:52:45.032Z",
      updatedAt: "2022-08-25T13:52:45.032Z",
    },
    card: {
      _id: "628b3df87716a164d30ae850",
      optionalDetail: [
        {
          object: "card",
          released_at: "1997-03-24",
          layout: "normal",
          image_uris: {
            small:
              "https://c1.scryfall.com/file/scryfall-cards/small/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            normal:
              "https://c1.scryfall.com/file/scryfall-cards/normal/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            large:
              "https://c1.scryfall.com/file/scryfall-cards/large/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            png: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.png?1616251341",
            art_crop:
              "https://c1.scryfall.com/file/scryfall-cards/art_crop/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
            border_crop:
              "https://c1.scryfall.com/file/scryfall-cards/border_crop/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.jpg?1616251341",
          },
          mana_cost: "{U}{U}",
          cmc: 2,
          type_line: "Instant",
          oracle_text: "Return target permanent to its owner's hand.",
          colors: ["U"],
          color_identity: ["U"],
          games: ["paper"],
          foil: false,
          nonfoil: true,
          finishes: ["nonfoil"],
          oversized: false,
          variation: false,
          set: "5ed",
          set_name: "Fifth Edition",
          set_type: "core",
          collector_number: "75s",
          digital: false,
          rarity: "common",
        },
      ],
      isDelete: false,
      name: "Boomerang",
      detail: "Return target permanent to its owner's hand.",
      price: {
        usd: "0.17",
        usd_foil: null,
        usd_etched: null,
        eur: "0.02",
        eur_foil: null,
        tix: null,
      },
      cardSerial: "2054",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/f/f/fffc0bfd-8e64-44bf-ae0a-5d2ee54c58df.png?1616251341",
      gameEdition: "628a86b00971793aeec9e059",
      stock: 10,
      createdAt: "2022-05-23T07:55:36.126Z",
      updatedAt: "2022-05-23T08:00:16.258Z",
      id: "628b3df87716a164d30ae850",
    },
    amount: 1,
    price: 0.17,
    summary: 0.17,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-25T13:52:45.038Z",
    updatedAt: "2022-08-25T13:52:45.038Z",
  },
  {
    _id: "630a32beb4f5ae5debacb441",
    order: {
      _id: "630a32beb4f5ae5debacb43f",
      orderNo: "2022-08-27-e28685bd-c165-4f51-8dc7-270f5fa84f2b",
      summary: 100,
      total: 150,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      user: "62c13542282d443c81ce700c",
      createdAt: "2022-08-27T15:05:34.033Z",
      updatedAt: "2022-08-27T15:05:34.033Z",
    },
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    amount: 1,
    price: 100,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-27T15:05:34.094Z",
    updatedAt: "2022-08-27T15:05:34.094Z",
  },
  {
    _id: "630a32beb4f5ae5debacb442",
    order: {
      _id: "630a32beb4f5ae5debacb43f",
      orderNo: "2022-08-27-e28685bd-c165-4f51-8dc7-270f5fa84f2b",
      summary: 100,
      total: 150,
      shipping: 50,
      isDeleted: false,
      isCanceled: false,
      isPickup: false,
      isDelivered: false,
      isConfirm: false,
      isPayment: false,
      user: "62c13542282d443c81ce700c",
      createdAt: "2022-08-27T15:05:34.033Z",
      updatedAt: "2022-08-27T15:05:34.033Z",
    },
    card: {
      _id: "628b18090494b954d4e27a08",
      optionalDetail: [
        {
          object: "card",
          mtgo_foil_id: 60618,
          released_at: "2016-06-10",
          layout: "normal",
          mana_cost: "{1}",
          cmc: 1,
          type_line: "Artifact",
          oracle_text:
            "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
          colors: [],
          color_identity: [],
          games: ["paper", "mtgo"],
          foil: true,
          nonfoil: true,
          finishes: ["nonfoil", "foil"],
          oversized: false,
          variation: false,
          set: "ema",
          set_name: "Eternal Masters",
          set_type: "masters",
          collector_number: "232",
          digital: false,
          rarity: "rare",
          security_stamp: "oval",
        },
      ],
      isDelete: false,
      name: "Sensei's Divining Top",
      detail:
        "{1}: Look at the top three cards of your library, then put them back in any order.\n{T}: Draw a card, then put Sensei's Divining Top on top of its owner's library.",
      price: {
        usd: "54.61",
        usd_foil: "102.77",
        usd_etched: null,
        eur: "37.00",
        eur_foil: "75.00",
        tix: "0.81",
      },
      cardSerial: "118421",
      img: "https://c1.scryfall.com/file/scryfall-cards/png/front/8/3/83c01c91-ea01-46c7-b94c-97777b968459.png?1580015272",
      gameEdition: "628a86a80971793aeec9dac5",
      stock: 10,
      createdAt: "2022-05-23T05:13:45.581Z",
      updatedAt: "2022-06-23T14:11:27.483Z",
      updateBy: "6301cfc40c0ef59f62cb27a6",
      id: "628b18090494b954d4e27a08",
    },
    amount: 2,
    price: 50,
    summary: 100,
    isConfirm: false,
    isDelete: false,
    createdAt: "2022-08-27T15:05:34.094Z",
    updatedAt: "2022-08-27T15:05:34.094Z",
  },
];
