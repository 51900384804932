import React, { useState } from "react";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import {
  getAllCardByName,
  getAllEditionByName,
  postGetAllEditionByGame,
} from "../Services/Crud";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCollection } from "../redux/action/dataAction";

const ModalCollection = ({ modalIsOpen, setIsOpen, optionsGame }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [collections, setCollections] = useState([]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      maxWidth: "900px",
      width: "80vw",
      height: "80vh",
      maxHeight: "800px",
      zIndex: "999",
    },
    overlay: {
      zIndex: "999",
    },
  };

  const styles = {
    innerContainer: {
      overflowY: "auto",
      height: "60vh",
      maxHeight: "700px",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onHanleGetEdition = (gameMasterId) => {
    postGetAllEditionByGame(gameMasterId)
      .then((res) => {
        setCollections(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCollectionClick = (data) => {
    console.log(data);

    dispatch(updateCollection(data.id));
    closeModal();
    history.push(`/game/${data.gameMaster.id}/advance`);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div>
        <div className="my-2 p-2 d-flex justify-content-between align-items-center  border-bottom">
          <div>All Collection</div>
          <GrClose onClick={closeModal} role="button" />
        </div>

        <div className="row w-100 mx-0">
          <div
            className="col-4 border px-0 py-2 h-100"
            style={{
              minHeight: "400px",
              borderRadius: "5px",
            }}
          >
            <div className="list-group">
              {optionsGame?.map((el) => {
                return (
                  <button
                    key={el.value}
                    className="list-group-item list-group-item-action border-0 "
                    onClick={() => onHanleGetEdition(el.value)}
                  >
                    {el.label}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="col-8 ">
            <div style={styles.innerContainer}>
              <div className="row g-2">
                {collections?.map((res) => {
                  return (
                    <a
                      key={res.id}
                      role="button"
                      className="col-4 limit-line-2"
                      onClick={() => onCollectionClick(res)}
                    >
                      {res.name}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCollection;
