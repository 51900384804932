import axios from "axios";
import React, { useEffect, useState } from "react";
import CardDetailContainer from "../Components/HomeComponents/CardDetailContainer";
import Banner from "../Components/Banner";
import GameSelectContainer from "../Components/HomeComponents/GameSelectContainer";
import { advanceSearchGame, getAllGameByDate } from "../Services/Crud";

import { useDispatch } from "react-redux";
import {
  updateAllProduct,
  updateGameSelected,
} from "../redux/action/dataAction";
import CardContainer from "../Components/CardContainer";

const Home = () => {
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [gRes, setGRes] = useState("");

  const getProduct = () => {
    getAllGameByDate()
      .then((res) => {
        setResults(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const initState = () => {
    dispatch(updateGameSelected(null));
  };

  useEffect(() => {
    initState();

    getProduct();
  }, []);

  return (
    <div>
      <Banner title="Welcome to Shop" />
      <div className="container-fluid">
        <CardContainer
          itemHeight="250px"
          itemWidth="400px"
          products={results.slice(0, 8)}
          textTitle="Last Update"
        />
        <CardContainer
          itemHeight="350px"
          itemWidth="550px"
          products={results.slice(0, 9)}
          textTitle="All Collection"
        />
      </div>
    </div>
  );
};

export default Home;
