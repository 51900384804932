import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiShoppingBag } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { MdLogout } from "react-icons/md";
import { kColor1 } from "../Contansts/kStyle";

import { Badge, Divider, Drawer, IconButton } from "@mui/material";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import { baseURL, mtgApi } from "../api/mtgAPI";
import CartItem from "../Elements/CartItem";
import { updateIsCartOpen } from "../redux/action/cartAction";
import { updateTextSearch } from "../redux/action/dataAction";
import { getAllGameByDate } from "../Services/Crud";
import { convertCurrency } from "../Services/Func";
import ModalCollection from "./ModalCollection";
import ModalLogin from "./ModalLogin";

const Header = () => {
  const dispatch = useDispatch();
  const hooksForm = useForm();
  const { register, control, handleSubmit, setValue } = hooksForm;
  const { addToast } = useToasts();
  const history = useHistory();
  const cart = useSelector((state) => state.cartReducer.cart);
  // const profile = useSelector((state) => state.profileReducer.profile);
  const profile = JSON.parse(localStorage.getItem("dtnProfile"));

  const cartDrawerOpen = useSelector(
    (state) => state.cartReducer.cartDrawerOpen
  );

  const token = window.localStorage.getItem("dtnToken");
  const [cartRes, setCartRes] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [optionsGame, setOptionGame] = useState([]);
  const [modalCollectionOpen, setModalCollectionOpen] = useState(false);
  const [modalLoginOpen, setModalLoginOpen] = useState(false);

  const styles = {
    container: {
      height: "100px",
      width: "100%",
      backgroundColor: kColor1,
      paddingInline: "30px",
    },
    sliderCart: {
      width: "500px",
      maxWidth: "80vw",
      position: "relative",
      height: "100vh",
    },
    drawerBody: {
      height: "calc(100vh - 330px)",
      overflowY: "auto",
    },
    offcanvasFooter: {
      height: "225px",
      backgroundColor: "#011627",
      color: "white",
      padding: "15px",
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
    textName: {
      fontSize: "14px",
      fontWeight: "400, Regular",
    },
    searchInput: {
      width: "300px",
    },
    searchDropdown: {
      width: "150px",
    },
  };

  const onHandleCloseCart = () => {
    dispatch(updateIsCartOpen(false));
  };

  const onHandleOpenCart = () => {
    dispatch(updateIsCartOpen(true));
  };

  const getOptionsGame = () => {
    getAllGameByDate()
      .then((res) => {
        const { data } = res.data;

        const list = data.map((res, index) => {
          return {
            label: res.name ?? res.title ?? index + 1,
            value: res.id,
          };
        });

        setOptionGame(list);
        setValue("gameSelected", list[0]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setCartRes(cart);
  }, [cart]);

  useEffect(() => {
    getOptionsGame();
  }, []);

  const onHandleLogin = () => {
    window.location = `${baseURL}/auth/google`;
    // window.location = "http://localhost:8080/auth/google";
  };

  const onHandleSearch = (ev) => {
    dispatch(updateTextSearch(ev.searchTerm));
    history.push(`/game/${ev.gameSelected.value}/advance`);
    setValue("searchTerm", "");
  };

  const onHandleCheckout = () => {
    const tempCartRes = [...cartRes];

    const groupCards = _.groupBy(tempCartRes, "cardSerial");

    const reGroupCards = Object.keys(groupCards).map((cs) => {
      return groupCards[cs];
    });

    const orderDetail = reGroupCards.map((el) => {
      return {
        cardSerial: el[0].cardSerial,
        data: el.map((c) => {
          return {
            condition: c.typeSelected?.toUpperCase(),
            priceType: "normal",
            amount: c.qty,
          };
        }),
      };
    });

    const data = {
      orderDetail,
    };

    mtgApi
      .post(`/order/confirmOrder`, data)
      .then((res) => {
        console.log({ res });
        const resp = res.data.data.orderDetail;

        const newResp = tempCartRes.map((item) => {
          const matched = resp.find((el) => el.cardSerial == item.cardSerial);

          return { ...item, canConfirm: matched.canConfirm };
        });

        setCartRes(newResp);
        // dispatch(updateCart(newResp));

        if (res.data.status === 50000) {
          addToast(res.data.message ?? "something out of stock", {
            appearance: "warning",
            autoDismiss: true,
          });
        } else {
          addToast(res.data.message ?? "Success", {
            appearance: "success",
            autoDismiss: true,
          });

          history.push("/checkout");
          dispatch(updateIsCartOpen(false));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onHandleLogout = () => {
    console.log("LOGOUT");
    // dispatch(updateToken(""));
    // dispatch(updateProfile({}));
    window.localStorage.removeItem("dtnToken");
    window.localStorage.removeItem("dtnProfile");

    history.go();
  };

  useEffect(() => {
    try {
      const datas = cartRes.map((item) => {
        return {
          price:
            item.price && item.typeSelected ? item.price[item.typeSelected] : 0,
          qty: item.qty,
        };
      });

      const total = datas.reduce((total, num) => {
        return total + num.price * num.qty;
      }, 0);

      setTotalPrice(+total);
    } catch (err) {
      console.log(err);
    }
  }, [cartRes]);

  const displayGameSearch = (
    <div>
      <form onSubmit={handleSubmit(onHandleSearch)}>
        <div className="input-group">
          <Controller
            rules={{ required: true }}
            name="gameSelected"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={optionsGame}
                style={styles.searchDropdown}
              />
            )}
          />
          <input
            type="text"
            className="form-control"
            style={styles.searchInput}
            {...register("searchTerm")}
          />
          <button className="btn btn-outline-secondary" type="submit">
            Search
          </button>
        </div>
      </form>
    </div>
  );

  const displayDrawerCart = (
    <Drawer anchor="right" open={cartDrawerOpen} onClose={onHandleCloseCart}>
      <div style={styles.sliderCart}>
        <div>
          <h4 className="p-4">Shopping Cart</h4>
          <Divider variant="middle" />
        </div>
        <div className="px-4 py-6" style={styles.drawerBody}>
          {cartRes.length ? (
            cartRes.map((item, index) => {
              return <CartItem key={index} item={item} />;
            })
          ) : (
            <p className="text-secondary my-2">
              There are no items in your cart.
            </p>
          )}
        </div>
        <div style={styles.offcanvasFooter}>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "32px" }}
          >
            <div>Total Price</div>
            <div>{convertCurrency(+totalPrice)}</div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "20px" }}
          >
            <div>{cartRes.length ?? 0} Items</div>
            <div>Price does not include shipping.</div>
          </div>
          <div className="mt-4">
            <button
              disabled={cartRes.length ? false : true}
              className="btn btn-primary w-100"
              onClick={onHandleCheckout}
            >
              Checkout <HiOutlineArrowNarrowRight />
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );

  const displayProfileBadge = (
    <div className="m-auto text-white" style={styles.textName}>
      <div className="">
        <div
          className="m-auto text-white"
          type="button"
          id="dropdownProfile"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src={profile?.image ?? "/assets/img/user.png"}
            // src="https://lh3.googleusercontent.com/a/AItbvmnAZ7-z1cd4EVuVD31taKtrCrCUbHcpLjDXxaM5=s96-c"
            alt="Profile img"
            style={{
              width: "30px",
              height: "30px",
              objectFit: "cover",
            }}
            className="rounded-circle"
          />

          <span className="ms-2">{profile?.displayName}</span>
        </div>
        <ul className="dropdown-menu" aria-labelledby="dropdownProfile">
          <li>
            <Link to="/myprofile" className="dropdown-item">
              <FaRegUser size="1.2rem" className="me-2" /> Manage Account
            </Link>
          </li>
          <li>
            <Link to="/myorders" className="dropdown-item">
              <BiShoppingBag size="1.3rem" className="me-2" /> My Orders
            </Link>
          </li>
          <li>
            <span
              to=""
              type="button"
              className="dropdown-item"
              onClick={onHandleLogout}
            >
              <MdLogout size="1.3rem" className="me-2" /> Logout
            </span>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={styles.container}
    >
      <div className="d-flex align-items-center ">
        <img
          src="/assets/img/logo4.png"
          width={100}
          alt="logo"
          onClick={() => history.push("/")}
          type="button"
        />
        <div className="d-none d-md-flex">
          <button
            className="btn mx-3 "
            onClick={() => setModalCollectionOpen(true)}
          >
            <img
              src="/assets/img/collection.png"
              alt="collection"
              className="mx-3"
            />
            <span className="text-white">All Collection</span>
          </button>

          {displayGameSearch}
        </div>
      </div>

      <div className="justify-content-between align-items-center  d-none d-md-flex">
        <div className="my-auto mx-3">
          <Badge badgeContent={cartRes.length} color="error">
            <IconButton onClick={onHandleOpenCart} sx={{ p: 0 }}>
              <AiOutlineShoppingCart size="2rem" color="white" />
            </IconButton>
          </Badge>
        </div>
        <div className="my-auto mx-3 text-white">CONTACT US</div>
        <div>
          {/* <button className="btn button--primary" onClick={onHandleLogin}>
            LOGIN
          </button> */}
          {!token ? (
            <button
              className="btn button--primary"
              // onClick={onHandleLogin}
              onClick={() => setModalLoginOpen(true)}
            >
              LOGIN
            </button>
          ) : (
            displayProfileBadge
          )}
        </div>
      </div>

      {displayDrawerCart}
      <ModalCollection
        modalIsOpen={modalCollectionOpen}
        setIsOpen={setModalCollectionOpen}
        optionsGame={optionsGame}
      />
      <ModalLogin modalIsOpen={modalLoginOpen} setIsOpen={setModalLoginOpen} />
    </div>
  );
};

export default Header;
