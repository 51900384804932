import React from "react";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";

const TopRoutePath = () => {
  const { url } = useRouteMatch();
  const gameSelected = useSelector((state) => state.dataReducer.gameSelected);

  const styles = {
    container: {
      backgroundColor: "#F4694F",
      height: "45px",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <div style={styles.container}>
      <Link to={`${url}/advance`} className="mx-2">
        Advance Search
      </Link>
      <Link to={`${url}/seal`} className="mx-2">
        Seal
      </Link>
      <Link to={`${url}/accessory`} className="mx-2">
        Accessory
      </Link>
    </div>
  );
};

export default TopRoutePath;
