import React from "react";
import CardDetailEl from "../../Elements/CardDetailEl";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import CardDetailElement from "../../Elements/CardDetailElement";

const CardDetailContainer = ({ products, title, pathUrl }) => {
  const history = useHistory();
  const styles = {
    cardContainer: {
      height: "454px",
      width: "260px",
    },
    textSeeAll: {
      color: "#F4694F",
      cursor: "pointer",
    },
  };

  const displayCards = (
    <>
      <div className="row row-cols-auto g-2 justify-content-start mt-2">
        {!!products.length &&
          products.map((item, index) => {
            return (
              <div className="col" key={index}>
                <div style={styles.cardContainer}>
                  <CardDetailElement item={item} />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );

  return (
    <div>
      <div className="my-5 px-5">
        {title ? (
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="fw-bold">{title}</h3>
            <span
              style={styles.textSeeAll}
              onClick={() => {
                history.push(pathUrl);
              }}
            >
              See all <HiOutlineArrowNarrowRight />
            </span>
          </div>
        ) : (
          ""
        )}
        {displayCards}
      </div>
    </div>
  );
};

export default CardDetailContainer;
