import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useSelector } from "react-redux";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import SpinnerTool from "../tools/Spinner/SpinnerTool";
import { version } from "../data/const";

const MainLayout = ({ children }) => {
  const history = useHistory();
  const { pathname } = history.location;

  const isLoading = useSelector((state) => state.dataReducer.isLoading);
  const route = useRouteMatch();
  const { url } = route;

  return (
    <React.Fragment>
      <div className="position-relative " style={{ minHeight: "100vh" }}>
        {isLoading && <SpinnerTool />}
        <Header />
        {children}
        <Footer />
        <div className="position-absolute bottom-0 start-0 p-2 text-secondary">
          version {version}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainLayout;
