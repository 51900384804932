import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { storeApi } from "../fakeApi/storeApi";
import { convertCurrency } from "../Services/Func";
import Select from "react-select";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../redux/action/cartAction";
import { useToasts } from "react-toast-notifications";
import { getCardDetail } from "../Services/Crud";

const ProductDetail = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartReducer.cart);
  const [priceType, setPriceType] = useState([]);
  const [typeSelected, setTypeSelected] = useState("");
  const [options, setOptions] = useState([]);

  const optionQty = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
  ];

  let { pId } = useParams();

  const [product, setProduct] = useState(null);
  const [qty, setQty] = useState(optionQty[0]);

  const itemInCart = cart.find(
    (item) =>
      item.cardSerial == product?.cardSerial &&
      item.typeSelected === typeSelected
  );

  const styles = {
    image: {
      width: "100%",
      maxWidth: "300px",
      // height: "488px",
      objectFit: "contain",
    },
    selectContainer: {
      maxWidth: "100px",
    },
  };

  const onSetQty = (ev) => {
    setQty(ev);
  };

  const onHandleSelectType = (event) => {
    // console.log(event.target.value);
    setTypeSelected(event.target.value);
    // setQty();
  };

  const onHandleAddCart = () => {
    const data = { ...product, qty: +qty.value, typeSelected };
    dispatch(updateCart(data, cart));

    addToast("เพิ่มสินค้าลงตะกร้าแล้ว", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const getProductDetail = (cardId) => {
    getCardDetail(cardId)
      .then((res) => {
        console.log("getCardDetail", res);

        const cardData = {
          cardSerial: pId,
          ...res.data,
        };

        setProduct(cardData);

        const stockObj = Object.keys(cardData.stock).map((el) => {
          return {
            type: el,
            stock: cardData.stock[el],
            price: cardData.price[el],
          };
        });

        const activeStock = stockObj.filter((item) => item.stock > 0);

        if (activeStock.length) {
          setPriceType(activeStock);
          setTypeSelected(activeStock[0].type);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (pId) {
      getProductDetail(pId);
    }
  }, [pId]);

  useEffect(() => {
    if (typeSelected) {
      const optionsByType =
        optionQty.slice(0, product?.stock[typeSelected?.toLowerCase()]) ?? [];

      const optionsSliceByCartItem = optionsByType.slice(
        0,
        itemInCart?.qty ? -(itemInCart?.qty ?? 0) : 8
      );

      setOptions(optionsSliceByCartItem);
      if (optionsSliceByCartItem.length) {
        setQty(optionsSliceByCartItem[0]);
      } else {
        setQty(null);
      }
    }
  }, [typeSelected, itemInCart]);

  return (
    <div className="container m-auto">
      <div className="row my-5">
        <div className="col-12 col-md-4">
          <img src={product?.img} style={styles.image} />
        </div>
        <div className="col-12 col-md-8">
          <h3 className="fw-bold">{product?.name}</h3>
          <p>{product?.category}</p>
          <h4 className="fw-bold">
            {product?.price &&
              convertCurrency(product.price[typeSelected?.toLowerCase()] ?? 0)}
          </h4>
          <div className="my-2">
            <div className="fw-bold">Product detail : </div>
            <div>{product?.detail}</div>
          </div>
          <div className="my-2">
            <div className="fw-bold">Rarity</div>
            <div>{product?.optionalDetail?.rarity}</div>
          </div>
          <div className="my-2">
            <div className="fw-bold">Condition</div>

            <div>
              {priceType?.map((item, index) => {
                return (
                  <span className="me-2" key={index}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="options-outlined"
                      id={`${index}-radio`}
                      autoComplete="off"
                      defaultChecked={!!(index === 0)}
                      onChange={onHandleSelectType}
                      value={item.type.toLowerCase()}
                    />
                    <label
                      className="btn btn-outline-success"
                      htmlFor={`${index}-radio`}
                    >
                      {item.type}
                    </label>
                  </span>
                );
              })}
            </div>
          </div>
          <div className="my-2">
            <div className="fw-bold">Quantity</div>
            <div style={styles.selectContainer}>
              <Select options={options} onChange={onSetQty} value={qty} />
            </div>
          </div>
          <div className="my-3">
            <button
              className="btn btn-outline-primary mx-2"
              onClick={onHandleAddCart}
              disabled={!options.length}
              // disabled={product && qty.value > 0 ? false : true}
            >
              <AiOutlineShoppingCart size="1.5rem" />
              <span className="ms-2">Add To Cart</span>
            </button>
            {/* <button className="btn btn-warning mx-2">Buy Now</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
