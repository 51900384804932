import React from "react";

const CardPreview = ({ item, popDetail }) => {
  const styles = {
    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  };

  return (
    <>
      <img src={item.imageURL} style={styles.img} alt={item.title} />
    </>
  );
};

export default CardPreview;
