import { TextField } from "@mui/material";
import React from "react";

const InputField = (props) => {
  const {
    keyName,
    label,
    helperText,
    required = false,
    shrink = true,
    type = "text",
    hookForm,
    hookProps,
  } = props;

  const {
    register,
    formState: { errors },
  } = hookForm;

  return (
    <TextField
      type={type}
      variant="outlined"
      label={label}
      {...register(keyName, { ...hookProps, required })}
      error={!!errors[keyName]}
      helperText={!!errors[keyName] && (helperText ?? "This field is required")}
      InputLabelProps={{ shrink }}
      fullWidth
      {...props}
    />
  );
};

export default InputField;
