import { indexOf, result } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import OrderItemEl from "../Elements/OrderItemEl";
import { updateIsLoading } from "../redux/action/dataAction";
import { getUserOrder } from "../Services/Crud";

const MyOrders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const profile = useSelector((state) => state.profileReducer.profile);
  const profile = JSON.parse(localStorage.getItem("dtnProfile"));

  // const profile = {
  //   userID: "630686dabeab5fb12c70a6d9",
  // };

  const [allOrder, setAllOrder] = useState([]);

  const getList = () => {
    dispatch(updateIsLoading(true));
    getUserOrder(profile?.userID)
      .then((res) => {
        const tempData = [...res.data];

        const sortedData = tempData.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        setAllOrder(sortedData);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch(updateIsLoading(false));
      });
  };

  useEffect(() => {
    if (profile?.userID) {
      getList();
    }
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <div className="H6">My Orders</div>
        {!!allOrder.length &&
          allOrder?.map((item, index) => {
            return (
              <div key={index}>
                {index !== 0 ? <hr /> : ""}
                <OrderItemEl item={item} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MyOrders;
